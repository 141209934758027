import uuid from 'uuid/v4'

let inventory = [{
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "new arrivals"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Stay a while. The Timber charme chocolat sofa is set atop an oak trim and flaunts fluffy leather back and seat cushions. Over time, this brown leather sofa’s full-aniline upholstery will develop a worn-in vintage look. Snuggle up with your cutie (animal or human) and dive into a bowl of popcorn. This sofa is really hard to leave. Natural color variations, wrinkles and creases are part of the unique characteristics of this leather. It will develop a relaxed vintage look with regular use.",
    "id": "p0000",
    "image": "https://content.zeotap.com/demo/images/couch1.png",
    "material": "wood",
    "name": "Timber Gray Sofa",
    "price": "1000"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "sofas",
      "living room"
    ],
    "color": "black",
    "currentInventory": 2,
    "description": "Stay a while. The Timber charme chocolat sofa is set atop an oak trim and flaunts fluffy leather back and seat cushions. Over time, this brown leather sofa’s full-aniline upholstery will develop a worn-in vintage look. Snuggle up with your cutie (animal or human) and dive into a bowl of popcorn. This sofa is really hard to leave. Natural color variations, wrinkles and creases are part of the unique characteristics of this leather. It will develop a relaxed vintage look with regular use.",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/couch5.png",
    "material": "wood",
    "name": "Carmel Brown Sofa",
    "price": "1000"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 8,
    "description": "Easy to love. The Sven in birch ivory looks cozy and refined, like a sweater that a fancy lady wears on a coastal vacation. This ivory loveseat has a tufted bench seat, loose back pillows and bolsters, solid walnut legs, and is ready to make your apartment the adult oasis you dream of. Nestle it with plants, an ottoman, an accent chair, or 8 dogs. Your call.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/couch6.png",
    "material": "wood",
    "name": "Mod Leather Sofa",
    "price": "800"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 10,
    "description": "You know your dad’s incredible vintage bomber jacket? The Nirvana dakota tan leather sofa is that jacket, but in couch form. With super-plush down-filled cushions, a corner-blocked wooden frame, and a leather patina that only gets better with age, the Nirvana will have you looking cool and feeling peaceful every time you take a seat. Looks pretty great with a sheepskin throw, if we may say so. With use, this leather will become softer and more wrinkled and the cushions will take on a lived-in look, like your favorite leather jacket.",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/couch7.png",
    "material": "wood",
    "name": "Thetis Gray Love Seat",
    "price": "900"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 7,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/couch8.png",
    "material": "wood",
    "name": "Sven Tan Matte",
    "price": "1200"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/couch9.png",
    "material": "wood",
    "name": "Otis Malt Sofa",
    "price": "500"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 9,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/couch10.png",
    "material": "wood",
    "name": "Ceni Brown 3 Seater",
    "price": "650"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "sofas",
      "living room"
    ],
    "color": "black",
    "currentInventory": 24,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/couch11.png",
    "material": "wood",
    "name": "Jameson Jack Lounger",
    "price": "1230"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "sofas"
    ],
    "color": "black",
    "currentInventory": 43,
    "description": "Easy to love. The Sven in birch ivory looks cozy and refined, like a sweater that a fancy lady wears on a coastal vacation. This ivory loveseat has a tufted bench seat, loose back pillows and bolsters, solid walnut legs, and is ready to make your apartment the adult oasis you dream of. Nestle it with plants, an ottoman, an accent chair, or 8 dogs. Your call.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/couch2.png",
    "material": "wood",
    "name": "Galaxy Blue Sofa",
    "price": "800"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 2,
    "description": "You know your dad’s incredible vintage bomber jacket? The Nirvana dakota tan leather sofa is that jacket, but in couch form. With super-plush down-filled cushions, a corner-blocked wooden frame, and a leather patina that only gets better with age, the Nirvana will have you looking cool and feeling peaceful every time you take a seat. Looks pretty great with a sheepskin throw, if we may say so. With use, this leather will become softer and more wrinkled and the cushions will take on a lived-in look, like your favorite leather jacket.",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/couch3.png",
    "material": "wood",
    "name": "Markus Green Love Seat",
    "price": "900"
  },
  {
    "catalog": "furniture",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 14,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/couch4.png",
    "material": "wood",
    "name": "Dabit Matte Black",
    "price": "1200"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 12,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/chair1.png",
    "material": "wood",
    "name": "Embrace Blue",
    "price": "300"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/chair2.png",
    "material": "wood",
    "name": "Nord Lounger",
    "price": "825"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 33,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/chair3.png",
    "material": "wood",
    "name": "Ceni Matte Oranve",
    "price": "720"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 23,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/chair4.png",
    "material": "wood",
    "name": "Abisko Green Recliner",
    "price": "2000"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/chair5.png",
    "material": "wood",
    "name": "Denim on Denim Single",
    "price": "1100"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 15,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/chair6.png",
    "material": "wood",
    "name": "Levo Tan Lounge Chair",
    "price": "600"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 44,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/chair7.png",
    "material": "wood",
    "name": "Anime Tint Recliner",
    "price": "775"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 17,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/chair8.png",
    "material": "wood",
    "name": "Josh Jones Red Chair",
    "price": "1200"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 28,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/chair9.png",
    "material": "wood",
    "name": "Black Sand Lounge",
    "price": "1600"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "furniture",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 31,
    "description": "You don’t have to go outside to be rugged. The Cigar rawhide sofa features a sturdy corner-blocked wooden frame and raw seams for that Malboro-person look. This brown leather sofa is cozy in a cottage, cabin, or a condo. And the leather (the leather!) becomes more beautiful with use: subtle character markings such as insect bites, healed scars, and grain variation reflects a real vintage. Saddle up and pass the remote.",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/chair10.png",
    "material": "wood",
    "name": "Mint Beige Workchair",
    "price": "550"
  },
  {
    "categories": [
      "Hatchback"
    ],
    "id": "p0000",
    "name": "Mercedes Benz A-Class",
    "price": "120000",
    "image": "https://content.zeotap.com/demo/images/A_CLASS_HATCHBACK.png",
    "description": "A-Class",
    "brand": "Mercedes Benz",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Benz",
    "vehicle_submodel": "benz_123",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [
      { "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "Limousine"
    ],
    "id": "p0001",
    "name": "Mercedes Benz C-Class",
    "price": "220000",
    "image": "https://content.zeotap.com/demo/images/C_CLASS_LIMOUSINE.png",
    "description": "C-Class",
    "brand": "Mercedes Benz",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Benz",
    "vehicle_submodel": "benz_223",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [{ "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "SUV"
    ],
    "id": "p0002",
    "name": "Mercedes Benz GLE",
    "price": "220000",
    "image": "https://content.zeotap.com/demo/images/GLE_SUV.png",
    "description": "GLE",
    "brand": "Mercedes Benz",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Benz",
    "vehicle_submodel": "benz_2223",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [{ "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "SUV"
    ],
    "id": "p0003",
    "name": "Mercedes Benz EQC",
    "price": "250000",
    "image": "https://content.zeotap.com/demo/images/EQC_SUV.png",
    "description": "EQC",
    "brand": "Mercedes Benz",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Benz",
    "vehicle_submodel": "benz_2213",
    "vehicle_engine": "i VTEC Enginef",
    
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [{ "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "Limousine"
    ],
    "id": "p0004",
    "name": "Mercedes Benz S-Class",
    "price": "520000",
    "image": "https://content.zeotap.com/demo/images/S_CLASS_LIMOUSINE.png",
    "description": "S-Class",
    "brand": "Mercedes Benz",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Benz",
    "vehicle_submodel": "benz_1223",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [
      {"configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "Hatchback"
    ],
    "id": "p0005",
    "name": "Volkswagen Polo GT",
    "price": "120000",
    "image": "https://content.zeotap.com/demo/images/polo_gt_hatchback.png",
    "description": "Polo GT",
    "brand": "Volkswagen",
    "currentInventory": 43,
    "vehicle_color": "Red",
    "vehicle_model": "Polo",
    "vehicle_submodel": "polo_223",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [
      {"configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "Sedan"
    ],
    "id": "p0006",
    "name": "Volkswagen Vento",
    "price": "220000",
    "image": "https://content.zeotap.com/demo/images/vento_sedan.png",
    "description": "Vento",
    "brand": "Volkswagen",
    "currentInventory": 43,
    "vehicle_color": "White",
    "vehicle_model": "Vento",
    "vehicle_submodel": "vento_332",
    "vehicle_engine": "i VTEC Enginef",
    
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [{ "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "categories": [
      "Hatchback"
    ],
    "id": "p0007",
    "name": "Volkswagen Tiguan",
    "price": "150000",
    "image": "https://content.zeotap.com/demo/images/tiguan_hatchback.png",
    "description": "Tiguan",
    "brand": "Volkswagen",
    "currentInventory": 43,
    "vehicle_color": "Red",
    "vehicle_model": "Tiguan",
    "vehicle_submodel": "tiguan_223",
    "vehicle_engine": "i VTEC Enginef",
    "vehicle_transmission": "Manual",
    "vehicle_trim": "abc",
    "vehicle_brand": "Honda",
    "vehicle_ecolabel": "green",
    "vehicle_type": "four wheeler",
    "vehicle_emissionstandard": "BS6",
    "vehicle_power": "117 bhp @ 6600 RPM",
    "vehicle_torque": "145 Nm @ 4600 RPM",
    "vehicle_fuelcapacity": "40 litres",
    "vehicle_seatingcapacity": "5",
    "vehicle_luggagecapacity": "150kg",
    "vehicle_tiresize": "175/65 R15",
    "vehicle_maxspeed": "195 Kmph",
    "vehicle_acceleration": "10 Seconds",
    "vehicle_fuelconsumption": "no",
    "vehicle_weight": "-",
    "catalog": "automobiles",
    "availableConfigs": [{ "configName": "fuelType", "configLabel": 'Fuel Type', 'options': [{label: 'petrol', value: 'petrol', name: 'fuelType'}, {label: 'electric', value: 'electric', name: 'fuelType'}] },
      {"configName": "transmissionType","configLabel": "Transmission Type", 'options': [{label: 'auto', value: 'auto', name: 'transmissionType'}, {label: 'manual', value: 'manual', name: 'transmissionType'}]},
      {"configName": "tyreType","configLabel": "Tyre Type", 'options': [{label: 'basic', value: 'basic', name: 'tyreType'}, {label: 'off road', value: 'off road', name: 'tyreType'},{label: 'all terrain', value: 'all terrain', name: 'tyreType'}]},
      {"configName": "color","configLabel": "Color", 'options': [{label: 'red', value: 'red', name: 'color'}, {label: 'black', value: 'black', name: 'color'},{label: 'white', value: 'white', name: 'color'}]}]
  },
  {
    "brand": "Lacoste",
    "catalog": "douglas",
    "categories": [
      "Body"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Style on Skin - Das Duschgel mit dem frischen, würzig-aromatischen Duft von Lacoste Pour Femme verwöhnt Haut und Sinne jeden Tag aufs Neue.",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/body0001.webp",
    "name": "Lacoste Pour Femme Sonderedition",
    "material": "Duschgel",
    "price": "9.99"
  },
  {
    "brand": "Biotherm",
    "catalog": "douglas",
    "categories": [
      "Body"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Mit der Lait Corporel Anti-Dessechant von Biotherm verwöhnst Du Deine Haut täglich. Dank der feuchtigkeitsspendenden Inhaltsstoffe wird die Hautschutzbarrier gestärkt. Die Körpermilch ist vor allem zur Pflege von trockener Haut optimal geeignet. Durch die vitalisierenden Vitamine und Extrakte aus Zitrusfrüchten hinterlässt sie einen zarten Duft und versorgt die Haut direkt bei der Anwendung mit der notwendigen Feuchtigkeit. Dank der leichten Textur hinterlässt die Lotion keine Rückstände und zieht schnell ein.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/body002.jpeg",
    "name": "Lait Corporel Anti-Desséchant",
    "material": "Duschgel",
    "price": "16.59"
  },
  {
    "brand": "Garnier",
    "catalog": "douglas",
    "categories": [
      "Body"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Das Natural Bronzer Selbstbräunungs-Mousse von Garnier Ambre Solaire schenkt Ihrer Haut in wenigen Minuten einen sommerlichen Teint. Die einzigartige Formel enthält einen pflanzlichen Wirkstoff, der der Haut eine natürlich aussehende Bräunung verleiht, an der Sie sich bis zu einer Woche lang erfreuen können.",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/body0003.webp",
    "name": "Ambre Solaire Natural Bronzer",
    "material": "Selbstbräuner",
    "price": "8.09"
  },
  {
    "brand": "Cerave",
    "catalog": "douglas",
    "categories": [
      "Body"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Die SA Glättende Reinigung von CeraVe wurde mit Dermatologen entwickelt, um trockene, raue oder unebene Haut zu glätten. Gleichzeitig stärkt die sanfte Formel die Hautschutzbarriere. Auch als Duschgel geeignet.Durch den peelenden Effekt von Salicylsäure glättet die Reinigung die Haut. Das Waschgel entfernt dazu Schmutz, Öl und Talg.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/body0004.webp",
    "name": "Cerave Sa Reinigung Lotion",
    "material": "Duschgel",
    "price": "14.29"
  },
  {
    "brand": "Clarins",
    "catalog": "douglas",
    "categories": [
      "Body"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Die Baume Corps Super Hydratant von Clarins zieht schnell ein, versorgt die Haut intensiv mit Feuchtigkeit und beruhigt sie. Die Haut wird dank der reichhaltigen Creme geschmeidiger, fester und sieht glatter aus.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/body0005.webp",
    "name": "Wohlfühl-ProgrammBaume Corps Super Hydratant XL",
    "material": "Bodylotion",
    "price": "37.99"
  },
  {
    "brand": "Kérastase",
    "catalog": "douglas",
    "categories": [
      "Hair"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Speziell für dünner werdendes Haar, das allmählich sichtbar an Fülle verliert, entwickelte Kérastase das Bain Densité. Das Haarbad setzt auf eine kraftvolle Mischung aus Hyaluronsäure, Gluco-Peptide und Ceramide, um dem Haar Struktur und mehr Griffigkeit zu verleihen. Bei jeder Anwendung stärkt das Shampoo der Pflegelinie Densifique die Haarfaser und schenkt ein volles, dichtes Haargefühl.",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/hair0001.jpeg",
    "name": "Densifique Bain Densité",
    "material": "Haarshampoo",
    "price": "20.99"
  },
  {
    "brand": "Garnier",
    "catalog": "douglas",
    "categories": [
      "Hair"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Die Wahre Schätze Feuchtigkeitsspendende Tiefenpflege-Maske von Garnier weiß die Bedürfnisse von sensiblem Haar bestens zu erfüllen – mit einer supersanften Rezeptur, die voller wichtiger Nährstoffe steckt. Vom Ansatz bis in die Spitzen wird das Haar verwöhnt, es kann an Geschmeidigkeit gewinnen, und die Kopfhaut erhält eine beruhigende Pflege. Das Geheimnis liegt im wohltuendem Duo aus Hafermilch und Reiscreme, das über feuchtigkeitsspendende, pflegende, schützende und besänftigende Eigenschaften verfügt, von denen Haar und Kopfhaut gleichermaßen profitieren.",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/hair0002.jpeg",
    "name": "Wahre SchätzeTiefenpflege-Maske Sanfte Hafermilch",
    "material": "Mask",
    "price": "4.49"
  },
  {
    "brand": "Olaplex",
    "catalog": "douglas",
    "categories": [
      "Hair"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Das OLAPLEX No. 7 Bonding Oil basiert auf der bewährten OLAPLEX-Formel mit patentierter Technologie - so schenkt es dem Haar nicht nur einen seidigen Glanz und eine fantastische Geschmeidigkeit, sondern auch eine intensiv stärkende Pflege. Das luxuriöse Haaröl besticht durch seine federleichte Formel, die keine Rückstände hinterlässt, Locken wunderbar definiert und die Haarfarbe fantastisch strahlen lässt. Das Bonding Oil kümmert sich um Frizz genauso wie um umherfliegende Haare und schützt zudem beim Styling mit Föhn, Glätteisen oder Lockenstab vor Temperaturen bis 330 °C. Die Formulierung ist alkoholfrei und vegan.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/hair0003.webp",
    "name": "Bond Maintenance Olaplex Bonding Oil 30 ml No.07",
    "material": "Haaröl",
    "price": "31.59"
  },
  {
    "brand": "Olaplex",
    "catalog": "douglas",
    "categories": [
      "Hair"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "OLAPLEX Intensive Bond Building Hair Treatment N°0 ist ein intensiver Repair Booster, der vom Haar absorbiert wird und auf die Behandlung mit OLAPLEX Hair Perfector N°3 vorbereitet. Als 2-Stufen-Home-Treatment reparieren N° 0 und N°3 gebrochene Disulfidbrücken im Haar und stärken nachhaltig die Haarstruktur. Wissenschaftlich nachgewiesen: 68% mehr Repartur und 3x mehr Stärkung des Haares. Wirkt wie ein Primer und bereitet das Haar auf die aufbauende Behandlung vor. Repariert Haarschäden bedingt durch chemische Behandlungen, Styling, Hitze (wie z. B. glätten, föhnen), Sonnenlicht uvm. Für alle Haartypen geeignet. Reicht für ca. 4-6 Anwendungen. Anwendung OLAPLEX Intensive Bond Building Treatment N°0 aufs trockene Haar auftragen, bis alle Haare damit benetzt sind. 10 Minuten einwirken lassen. Nicht ausspülen! OLAPLEX  Hair Perfector",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/hair0004.webp",
    "name": "Olaplex - Intensive Bond Building Hair Treatment No. 0",
    "material": "Kopfhautpflege",
    "price": "31.59"
  },
  {
    "brand": "Revlon",
    "catalog": "douglas",
    "categories": [
      "Hair"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Der Revlon Professional Equave Detangling Conditioner ist für alle Haartypen geeignet und schenkt trockenem, angegriffenem Haar intensive Pflege. Er besitzt eine verbesserte 2-Phasen-Formel, die dem Haar ein außergewöhnliches Gefühl verleiht. Die Haare erhalten eine Extraportion Feuchtigkeit und werden repariert. Das Resultat ist geschmeidiges und glänzendes Haar.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/hair0005.webp",
    "name": "Instant Detangling Conditioner For Normal to Dry Hair",
    "material": "Haarspülung",
    "price": "13.99"
  },
  {
    "brand": "Estée Lauder",
    "catalog": "douglas",
    "categories": [
      "Make Up"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Ein makelloser Teint, den ganzen Tag über: Diese unkomplizierte Foundation von EstÉe Lauder sorgt für einen natürlich-frischen Teint, passt sich unterschiedlichen Lichtverhältnissen optimal an und ist farbtreu - selbst, wenn Sie nonstop aktiv sind. Mit Double Wear Stay-in-Place fühlt sich Ihre Haut seidig-glatt, angenehm und niemals trocken an. Die ölfreie Formel ist dermatologisch getestet, nicht komedogen und parfümfrei.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/makeup0001.webp",
    "name": "Double Wear Stay-in-Place Makeup SPF 10",
    "material": "Foundation",
    "price": "29.95"
  },
  {
    "brand": "IT Cosmetics",
    "catalog": "douglas",
    "categories": [
      "Make Up"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Die Your Skin But Better™ CC+™ Cream LSF 50+ von IT Cosmetics ist eine hochpigmentierte Multitasking-Creme, die Teint-Unregelmäßigkeiten und dunkle Schatten zuverlässig abdeckt, ohne sich dabei in feinen Fältchen abzusetzen. Die Formel vereint Feuchtigkeitscreme, Anti-Aging-Serum, Concealer, Foundation und Sonnenschutz in einem Produkt und verfügt über einen mineralischen UV-A-/UV-B-Filter mit Lichtschutzfaktor 50+, der die Haut vor schädlicher Sonneneinstrahlung und damit vor vorzeitigen Alterungsanzeichen schützt.",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/makeup0002.webp",
    "name": "Your Skin But Better™ CC+™ Cream LSF 50+",
    "material": "CC Creamm",
    "price": "39.99"
  },
  {
    "brand": "HUDA Beauty",
    "catalog": "douglas",
    "categories": [
      "Make Up"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Mit dem Liquid Matte Lipstick präsentiert Huda Beauty eine flüssige Lippenstiftformel mit buttrig-mattem Finish. Die innovative Rezeptur ist angereichert mit einer nährenden Mischung aus Lycopin, Olivenöl, Vitaminen und essenziellen Fettsäuren. Sie punktet mit einer brillanten Farbabdeckung und einem angenehmen, federleichten Tragegefühl, schuppt nicht, lässt sich streifenfrei auftragen und ist wisch- & übertragungsfest. Der Lippenstift ist sowohl allein als auch mit Lip Contour 2.0 tragbar.",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/makeup0003.webp",
    "name": "Liquid Matte Lipstick",
    "material": "Lippenfarbe",
    "price": "21.99"
  },
  {
    "brand": "NÉONAIL",
    "catalog": "douglas",
    "categories": [
      "Make Up"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Mit dem Liquid Matte Lipstick präsentiert Huda Beauty eine flüssige Lippenstiftformel mit buttrig-mattem Finish. Die innovative Rezeptur ist angereichert mit einer nährenden Mischung aus Lycopin, Olivenöl",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/makeup0004.webp",
    "name": "Carnival Cities",
    "material": "Nagellack",
    "price": "10.89"
  },
  {
    "brand": "Yves Saint Lauren",
    "catalog": "douglas",
    "categories": [
      "Make Up"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Schutz, Pflege, Glanz mit Feuchtigkeitseffekt, ebenmäßige Farbe, langer Halt: Rouge VoluptÉ Shine lässt die Lippen stundenlang in herrlich verführerischen Nuancen erstrahlen. Dank pflegender Ingredienzen verleiht er den Lippen Volumen, enthüllt ihre natürliche Schönheit und schenkt pures Wohlgefühl, ein hoher Anteil an weichen Wachsen bewahrt die zarte Haut der Lippen vor dem Austrocknen. Zartschmelzende Texturen vereinen sich mit leuchtenden Farben, umspielt von geheimnisvollem Glanz: Erleben Sie unwiderstehliche Ausstrahlung und tauchen Sie in ein Meer aus purer Sinnlichkeit - Rouge VoluptÉ Shine ist ganz und gar YSL.",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/makeup0005.webp",
    "name": "Rouge Volupté Shine",
    "material": "Lippenstift",
    "price": "26.89"
  },
  {
    "brand": "Carolina Herrera",
    "catalog": "douglas",
    "categories": [
      "Parfum"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/parfum0001.webp",
    "name": "Good Girl Eau de Parfum Spray",
    "material": "Parfum",
    "price": "61.99"
  },
  {
    "brand": "Chloé",
    "catalog": "douglas",
    "categories": [
      "Parfum"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "ChloÉ Love Story erzählt eine moderne Liebesgeschichte zweier Menschen in Paris. Im Kopf entfalten sich erfrischende und sinnliche Noten von Neroli und Orangenblüte. Im Herzen verkörpert Stephanotis die pulsierende Weiblichkeit. In der Basis verbleibt eine Spur von Zedernholz. Der zart goldene Flakon aus plissiertem Glas erinnert an ein Schloss, das die junge Liebe besiegelt.",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/parfum0002.webp",
    "name": "Chloé Love Story",
    "material": "Eau de Parfum",
    "price": "36.99"
  },
  {
    "brand": "Marc Jacobs",
    "catalog": "douglas",
    "categories": [
      "Parfum"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Der faszinierende Damenduft Daisy Love des berühmten Mode- und Parfumdesigners Marc Jacobs ist seit dem Jahr 2018 verfügbar. Das bezaubernde Eau de Toilette Spray begeistert mit seiner fröhlichen und mädchenhaften Ausstrahlung, die zu Damen in jedem Alter hervorragend passt. Obwohl Daisy Love einen ausgesprochen legeren und unbeschwerten Charakter besitzt, eignet es sich als Duftaccessoire für den Berufsalltag und die Freizeit genauso gut wie für einen gelungenen Auftritt beim Ausgehen.",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/parfum0003.webp",
    "name": "Daisy LoveEau de Toilette Spray",
    "material": "Eau de Toilette",
    "price": "38.99"
  },
  {
    "brand": "Lancôme",
    "catalog": "douglas",
    "categories": [
      "Parfum"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Lassen Sie sich von zarten Gourmand-Noten und einem Hauch von Iris, der Blume des Glücks, den Tag erhellen – La vie est belle Happiness Shot von Lancôme ist der Duft, der Ihren Sommer mit nicht enden wollender Freude bereichert. Die Komposition eröffnet mit den fruchtigen Noten von Birne und Brombeere, während das Herz ein florales Bouquet aus Iriswurzel, Orangenblüten und Jasmin Sambac Absolue offenbart. Die Basis des unwiderstehlichen Eau de Parfums verführt mit einer sinnlichen Patschuli-Essenz – eine einzigartige Assemblage, die jeder Frau ein Lächeln ins Gesicht zaubert. La vie est belle Happiness Shot Eau de Parfum präsentiert sich in einer praktischen Reisegröße – die größte Freude, verpackt im kleinsten Flakon.",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/parfum0004.webp",
    "name": "La vie est belle",
    "material": "Eau de Parfum",
    "price": "23.99"
  },
  {
    "brand": "Mugler",
    "catalog": "douglas",
    "categories": [
      "Parfum"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Delikates für die Sinne - Angel von MUGLER: Ein Parfüm, das die verspielte Seite einer femininen Frau auf sinnliche Weise betont. Bergamotte sorgt im Auftakt für Frische und eine Aura aus Unschuld. Dewberry und goldener Honig geben dem Herzen delikate Fruchtigkeit. Patschuli, Vanille und Gourmetaromen erzeugen eine sinnlich-orientalische Note. Der von Thierry Mugler entworfene, nachfüllbare Sternenflakon besticht durch die einzigartige Glasverarbeitung des französischen Traditionshauses Brosse. Jeder Flakon wir von Hand poliert und mit der silber-glitzernden Verschlusskappe gekrönt.",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/parfum0005.webp",
    "name": "Angelnachfüllbar",
    "material": "Eau de Parfum",
    "price": "39.99"
  },
  {
    "brand": "Clinique",
    "catalog": "douglas",
    "categories": [
      "Face"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Reichhaltige Reinigungscreme, die Makeup, Schmutz und Rückstände schnell, einfach und gründlich entfernt. Bei der Anwendung verwandelt sich die Creme in ein luxuriöses, seidiges Öl. Mit Wasser oder Kleenex abnehmen. Für alle Hauttypen. Augenärztlich getestet. Unsere Clinique Clean Philosophie: Einfach. Sicher. Wirksam.  Entwickelt für optimale Resultate &ndash ohne Hautirritationen.  Allergiegetestet.  100% Parfumfrei.",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/face0001.webp",
    "name": "Take the Day Off - Cleansing Balm 125ml",
    "material": "Gesichtsreinigung",
    "price": "20.99"
  },
  {
    "brand": "Biotherm",
    "catalog": "douglas",
    "categories": [
      "Face"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Blue Therapy Accelerated Cream von Biotherm beschleunigt die Regenerationskraft der Haut und mindert effektiv die sichtbaren Zeichen der Hautalterung.",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/face0002.webp",
    "name": "Blue Therapy - Regeneriert Zeichen der Hautalterung Accelerated Cream",
    "material": "Gesichtscreme",
    "price": "74.99"
  },
  {
    "brand": "Dr. Susanne von Schmiedeberg",
    "catalog": "douglas",
    "categories": [
      "Face"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Das Youth Booster A.G.E.-Reverse Double Serum wirkt zweifach und effektiv gegen Hautalterung, indem es die Zellverzuckerung verlangsamt und bereits versteiften Kollagenfasern wieder mehr Spannkraft zurückgibt. Der einzigartige Wirkstoff L-Carnosine kann A.G.E.s (Advanced Glycation Endproducts), die in der Haut für deren Verzuckerung verantwortlich sind, reduzieren und somit den Prozess der Hautalterung verlangsamen. Gleichzeitig kann der innovative Yuyuba-Extract bereits verzuckerten Kollagenfasern ihre Elastizität zurückgeben, indem es den Abbau bestehender A.G.E.s unterstützt. Hochkonzentrierte Hyaluronsäure durchfeuchtet intensiv bis in die tieferen Hautschichten. Das sichtbare Ergebnis: jugendlich, straffere Haut. Für alle Hauttypen geeignet.",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/face0003.webp",
    "name": "YOUTH BOOSTER A.G.E.-Reverse Double Serum",
    "material": "Serum",
    "price": "89.99"
  },
  {
    "brand": "Shisheido",
    "catalog": "douglas",
    "categories": [
      "Face"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Entdecken Sie die Vital Perfection Uplifting & Firming Cream in der limitierten Chinese New Year Limited Edition. Proaktive Pflege für straffere Haut und eine strahlend schöne Zukunft. Inspiriert von der jüngsten ganzheitlichen Forschung in Haut-, Neuro- und anderen Gesundheitswissenschaften hat Shiseido diese revolutionäre, vielseitige revitalisierende Hautpflege entwickelt.",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/face0004.webp",
    "name": "VITAL PERFECTIONVital Perfection - Uplifting & Firming Cream",
    "material": "Gesichtscreme",
    "price": "81.00"
  },
  {
    "brand": "Lancôme",
    "catalog": "douglas",
    "categories": [
      "Face"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Das Advanced Génifique Microbiome Serum von Lancôme mit neuer Formel reaktiviert die innere Kraft Ihrer Haut und lässt sie jugendlich strahlen. Die Anti-Aging Hautpflege mildert die Zeichen der Hautalterung: Spannkraft, Hautstruktur und Reinheit verbessern sich und feine Linien und Falten werden reduziert. Sie erhalten ein ebenmäßigeres Hautbild und neue Strahlkraft.",
    "id": "p0025",
    "image": "https://content.zeotap.com/demo/images/face0005.webp",
    "name": "Advanced Génifique Serum",
    "material": "Anti-Aging Pflege",
    "price": "29.99"
  },
  {
    "brand": "MONDO",
    "catalog": "porta",
    "categories": [
      "new arrivals"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Mit dem 218 cm breiten MONDO 3-Sitzer Ledersofa BIALA in der Farbe Mercury Olive wird das Wohnzimmer mit einer top modernen Sitzgelegenheit mit edlem Design und hohem Sitzkomfort bereichert. Das MONDO Ledersofa BIALA liegt mit grünem Lederbezug und matt schwarzen Metallfüßen genau im Trend.",
    "id": "p0000",
    "image": "https://content.zeotap.com/demo/images/couch1.webp",
    "material": "Leder",
    "name": "MONDO Ledersofa 3-Sitzer BIALA",
    "price": "2379.00"
  },
  {
    "brand": "SCHÖNER WOHNEN-KOLLEKTION",
    "catalog": "porta",
    "categories": [
      "sofas",
      "living room"
    ],
    "color": "black",
    "currentInventory": 2,
    "description": "Das 210 cm breite aus der SCHÖNER WOHNEN-Kollektion kommende Sofa 3-Sitzer PEARL besticht mit seinem feinen Stoffbezug in Platingrau und den dauerelastischen Stahl-Wellenfedern. Sie sorgen mitsamt des hochwertigen PUR-Schaums für eine sehr gute Polsterung und mit den formstabilen Rückenkissen für einen langanhaltenden Sitzkomfort.",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/couch2.webp",
    "material": "Textil",
    "name": "SCHÖNER WOHNEN-Kollektion Sofa 3-Sitzer PEARL",
    "price": "1254.00"
  },
  {
    "brand": "QUARTIER",
    "catalog": "porta",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 8,
    "description": "In Kürze steht hier eine ausführliche Produktbeschreibung für den Artikel: Sofa BAGGIO 3-Sitzer silbergrau Die wichtigsten Infos über Maße und Ausstattung dieses Artikels sind in den Produktdetails zu finden.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/couch3.webp",
    "material": "Textil",
    "name": "Sofa BAGGIO 3-Sitzer silbergrau",
    "price": "849.99"
  },
  {
    "brand": "SCHRÖNO",
    "catalog": "porta",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 10,
    "description": "VINTAGE I ist ein 3-Sitzer Sofa aus dem Hause SCHRÖNO, welches in jedem Ambiente als Blickfang auftritt. Attraktives Design wird von hohem Komfort begleitet, erlesene Materalien in exzellenter Verarbeitung und ein faszinierender, mehrfarbiger Bezug generieren ein einzigartiges Erscheinungsbild.",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/couch4.webp",
    "material": "Textil",
    "name": "SCHRÖNO Sofa 3-Sitzer VINTAGE",
    "price": "3399.00"
  },
  {
    "brand": "Jason Bourne",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 7,
    "description": "Das attraktiv gestaltete 3-Sitzer Sofa JONAS in Hellgrün ist das ideale Sitzmöbel für modern eingerichtete Wohnbereiche. Mit einem Stellmaß von 185 x 76 cm passt das Sofa in große und kleine Wohnzimmer. Durch die tolle Optik ist das Sofa einzeln stellbar oder mit einem farblich passenden Sessel kombinierbar.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/couch5.webp",
    "material": "Textil",
    "name": "Sofa 3-Sitzer JONAS hellgrün",
    "price": "499.99"
  },
  {
    "brand": "CASAVANTI",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "Das Schlafsofa JEFF der Marke CASAVANTI ist für den Einsatz im Wohnzimmer ebenso geeignet wie für die Ausstattung eines Gästezimmers. Die dezente Farbgebung in grau harmoniert hervorragend mit vorhandenem Interieur.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/couch6a.webp",
    "material": "Textil",
    "name": "CASAVANTI Schlafsofa JEFF 200 cm grau",
    "price": "499.00"
  },
  {
    "brand": "QUARTIER",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 9,
    "description": "Mit dem komfortablen Ecksofa SANTANA in Grey zieht die perfekte Basis für gemütliche Stunden mit der Familie in moderne Wohnzimmer ein. Zudem überzeugt das Sofa mit einem eleganten Design, das sich problemlos integrieren lässt.",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/couch6.webp",
    "material": "Textil",
    "name": "Ecksofa SANTANA 319 x 194 cm grau",
    "price": "1499.99"
  },
  {
    "brand": "LIV‘IN",
    "catalog": "porta",
    "categories": [
      "sofas",
      "living room"
    ],
    "color": "black",
    "currentInventory": 24,
    "description": "Das von porta Möbel angebotene Sofa ARUBA der Marke LIV'IN stellt sich in vielen Hausbereichen als gemütliches Sitzmöbel zur Verfügung. Der Zweisitzer strahlt dabei zeitlose Eleganz aus und überzeugt mit komfortablen Polstern die ganze Familie. Selbstverständlich lassen sich die Vorzüge dieses Sofas auch beim gemeinsamen Speisen genießen.",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/couch7.webp",
    "material": "Textil",
    "name": "LIV´IN 2-Sitzer ARUBA ca. 170 x 99 x 82 cm",
    "price": "599.99"
  },
  {
    "brand": "LIV‘IN",
    "catalog": "porta",
    "categories": [
      "sofas"
    ],
    "color": "black",
    "currentInventory": 43,
    "description": "Der komfortable 2-Sitzer MALMÖ von LIV'IN präsentiert sich elegant in einem leicht skandinavischen Look. Durch seine einzigartige Optik mit niedrigen Rückenpolstern, festen Armlehnen und einem Bezug im attraktiven Farbton Grey ist das Sofa ein Blickfang und in moderne und klassische Raumkonzepte integrierbar.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/couch8.webp",
    "material": "Textil",
    "name": "LIV'IN Sofa 2-Sitzer MALMÖ grey",
    "price": "499.99"
  },
  {
    "brand": "HIMOLLA",
    "catalog": "porta",
    "categories": [
      "new arrivals",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 2,
    "description": "Das himolla Ledersofa MAINSTADT in Zinngrau steht für Komfort und Eleganz. Es überzeugt mit seinem formschönen Design und dem besonderen Charme, der von dem grauen Lederbezug ausgeht. Die schlanken Füße aus Metall sind dazu passend in Grau gehalten und sorgen für ein leichtes Erscheinungsbild.",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/couch9.webp",
    "material": "Leder",
    "name": "himolla Ledersofa 2-Sitzer MAINSTADT zinngrau",
    "price": "2599.00"
  },
  {
    "brand": "ROLF BENZ",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 14,
    "description": "Das schwarze ROLF BENZ 3-Sitzer Ledersofa VIDA I mit einer Breite von 188 cm lässt sich mit seinem klassisch schönen Design vielseitig einsetzen und macht in Wohn- und Geschäftsräumen eine hervorragende Figur.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/couch10.webp",
    "material": "Leder",
    "name": "ROLF BENZ Ledersofa 3-Sitzer VIDA I 188 cm schwarz",
    "price": "6815.00"
  },
  {
    "brand": "ROLF BENZ",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 14,
    "description": "Das hochwertige Ecksofa ONDA von ROLF BENZ ist mit einem Stoffbezug im Farbton Eisengrau ausgestattet. Es hat ein Stellmaß von 184 x 140 cm (BxT).",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/couch11.webp",
    "material": "Textil",
    "name": "ROLF BENZ Ecksofa ONDA I eisengrau",
    "price": "804.00"
  },
  {
    "brand": "SCHÖNER WOHNEN-KOLLEKTION",
    "catalog": "porta",
    "categories": [
      "on sale",
      "sofas"
    ],
    "color": "black",
    "currentInventory": 12,
    "description": "Dieses 2-Sitzer Sofa PEARL ist 190 cm breit und mit einem Stoffbezug in Anthrazitgrau bezogen. Ein modernes und geradliniges Design zeichnet dieses komfortable Sitzmöbel aus der SCHÖNER WOHNEN-Kollektion aus. Der Stoffbezug besteht aus einem strapazierfähigen Mischgewebe und ist durch einen Baumwollanteil von 16 Prozent besonders hautsympathisch und anschmiegsam.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/couch12.webp",
    "material": "Textil",
    "name": "SCHÖNER WOHNEN-Kollektion Sofa 2-Sitzer PEARL Stoff Trend platingrau",
    "price": "1170.00"
  },
  {
    "brand": "INTERHOME",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "Der grüne INTERhome Ohrensessel PETYR ist ein gelungener Blickfang in Ihrem Wohnzimmer. Außerdem schafft er eine gemütliche Sitzgelegenheit zum Lesen, Fernsehen oder für das entspannte Nickerchen am Nachmittag. Die Polsterung besteht aus einem formstabilen Polyätherschaum und schafft in Kombination mit der hohen Rückenlehne und den Ohren einen erstklassigen Sitzkomfort.",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/chair1.webp",
    "material": "Textil",
    "name": "INTERhome Ohrensessel PETYR Stoffbezug grün",
    "price": "299.00"
  },
  {
    "brand": "OLE GUNDERSON",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 33,
    "description": "Modern und doch elegant präsentiert sich der Ole Gunderson Sessel CASEY in Dunkelgrau. Die komfortable Sitzgelegenheit mit hoher Lehne und festen Armlehnen sorgt für angenehmen Sitzkomfort und wird mit seinem klassischen Design im Ohrenbacken-Stil in jedem Raum zum Blickfang.",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/chair2.webp",
    "material": "Textil",
    "name": "Ole Gunderson Sessel CASEY dunkelgrau",
    "price": "299.00"
  },
  {
    "brand": "QUARTIER",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 23,
    "description": "Der Sessel JONAS in Hellgrün überzeugt mit einem modernen Design mit sanft abgerundeten Elementen. Außerdem bietet er einen erstklassigen Sitzkomfort und schafft eine weitere gemütliche Sitzgelegenheit im Wohnzimmer.",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/chair3.webp",
    "material": "Textil",
    "name": "Sessel JONAS hellgrün",
    "price": "299.99"
  },
  {
    "brand": "QUARTIER",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 13,
    "description": "Der Sessel OLSEN besticht durch sein ausgewogenes Design. Das dezente Petrol-Grau des Stoffbezugs und der helle Naturholzton der vier leicht ausgestellten Füße verleihen dem Sessel eine behagliche Ausstrahlung.",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/chair4.webp",
    "material": "Textil",
    "name": "Sessel OLSEN Stoffbezug Petrol-Grau gemustert",
    "price": "179.99"
  },
  {
    "brand": "ROLF BENZ",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 15,
    "description": "Der ROLF BENZ Loungesessel 684 ist mit Leder in Schwarz bezogen. Er misst in Breite und Tiefe 81 x 77 cm. Der silberfarbige Fuß besteht aus einer Holznachbildung.",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/chair5.webp",
    "material": "Leder",
    "name": "ROLF BENZ Loungesessel 684 Leder schwarz",
    "price": "1999.99"
  },
  {
    "brand": "QUARTIER",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 44,
    "description": "Der rote Sessel LONDON mit edlem Samt-Bezug verleiht dem Wohnzimmer ein extravagantes Ambiente und zieht mit seinem Design im Chesterfield Stil alle Blicke auf sich.",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/chair6.webp",
    "material": "Textil",
    "name": "Sessel LONDON Samt rot",
    "price": "349.99"
  },
  {
    "brand": "INTERHOME",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 17,
    "description": "Der INTERhome Sessel ADELINE besitzt einen Stoffbezug in Altrosa. Die Füße sind aus Kiefernholz gefertigt. Das Möbel besitzt feste Armteile.",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/chair7.webp",
    "material": "Textil",
    "name": "INTERhome Sessel ADELINE Stoffbezug altrosa",
    "price": "229.99"
  },
  {
    "brand": "KARE DESIGN",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 28,
    "description": "Mit seinem extravaganten Design schlägt der in elegantem Silbergrau gestaltete KARE DESIGN Sessel TUDOR geschmackvoll eine stilistische Brücke zwischen dem antiken Tudorstil und moderner Leichtigkeit.",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/chair8.webp",
    "material": "Textil",
    "name": "KARE DESIGN Sessel TUDOR silbergrau",
    "price": "559.00"
  },
  {
    "brand": "ROLF BENZ",
    "catalog": "porta",
    "categories": [
      "on sale",
      "chairs"
    ],
    "color": "black",
    "currentInventory": 31,
    "description": "Der ROLF BENZ Sessel 011 in Graubeige schafft eine bequeme Sitzgelegenheit im Wohnzimmer. Er eignet sich zum Relaxen, Lesen oder Fernsehen.",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/chair9.webp",
    "material": "Textil",
    "name": "ROLF BENZ Sessel 011 graubeige",
    "price": "2999.99"
  },
  {
    "brand": "Swarovski Case",
    "catalog": "swarovski",
    "categories": [
      "Accessories"
    ],
    "currentInventory": 4,
    "description": "Pink case compatible with Apple watch",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/case-compatible-with-apple-watch--pink-swarovski-5619486.png",
    "name": "Apple watch case",
    "price": "79"
  },
  {
    "brand": "Swarovski Pen",
    "catalog": "swarovski",
    "categories": [
      "Accessories"
    ],
    "currentInventory": 4,
    "description": "Classic, black lacquered ballpoint pen",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/ballpoint-pen--classic--black--black-lacquered-swarovski-5631209.png",
    "name": "Ballpoint pen",
    "price": "65"
  },
  {
    "brand": "Swarovski Pod",
    "catalog": "swarovski",
    "categories": [
      "Accessories"
    ],
    "currentInventory": 4,
    "description": "Blue, Silver-tone plated Pod jewelry",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/pod-jewelry--blue--silver-tone-plated-swarovski-5619485.png",
    "name": "Pod Jewelry",
    "price": "110"
  },
  {
    "brand": "Swarovski Pen",
    "catalog": "swarovski",
    "categories": [
      "Accessories"
    ],
    "currentInventory": 4,
    "description": "Pink, rose gold-tone plated crystalline love ballpoint pen",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/crystalline-love-ballpoint-pen--pink--rose-gold-tone-plated-swarovski-5595674.png",
    "name": "Crystalline Love ballpoint pen",
    "price": "55"
  },
  {
    "brand": "Swarovski Case",
    "catalog": "swarovski",
    "categories": [
      "Accessories"
    ],
    "currentInventory": 4,
    "description": "Swan, iphone 13 Pro max, blue smartphone case",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/smartphonecase.png",
    "name": "Smartphone Case",
    "price": "110"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "Jewelry"
    ],
    "currentInventory": 4,
    "description": "Pear cut, white, rhodium plated earrings",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/attract-hoop-earrings--circle--white--rhodium-plated-swarovski-5563278.png",
    "name": "Attract hoop earrings",
    "price": "80"
  },
  {
    "brand": "Swarovski Bracelet",
    "catalog": "swarovski",
    "categories": [
      "Jewelry"
    ],
    "currentInventory": 4,
    "description": "Round cut, white, rhodium plated tennis bracelet",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/tennis-deluxe-bracelet--round-cut--white--rhodium-plated-swarovski-5409771.png",
    "name": "Tennis Deluxe bracelet",
    "price": "160"
  },
  {
    "brand": "Swarovski Necklace",
    "catalog": "swarovski",
    "categories": [
      "Jewelry"
    ],
    "currentInventory": 4,
    "description": "Infinity, white, rhodium plated necklace",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/swarovski-infinity-necklace--infinity--white--rhodium-plated-swarovski-5520576.png",
    "name": "Swarovski Infinity necklace",
    "price": "90"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "Jewelry"
    ],
    "currentInventory": 4,
    "description": "Square cut, small, rose gold-tone plated earrings",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/attract-stud-earrings--square-cut--small--white--rhodium-plated-swarovski-5509936.png",
    "name": "Attract stud earrings",
    "price": "60"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "Jewelry"
    ],
    "currentInventory": 4,
    "description": "Heart, white, rose gold-tone plated earrings",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/lifelong-heart-drop-earrings--heart--white--rose-gold-tone-plated-swarovski-5517942.png",
    "name": "Lifelong Heart drop earrings",
    "price": "90"
  },
  {
    "brand": "Swarovski Watches",
    "catalog": "swarovski",
    "categories": [
      "Watches"
    ],
    "currentInventory": 4,
    "description": "Metal bracelet, gray, champagne gold-tone finish watch",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/crystalline-glam-watch--metal-bracelet--gray--champagne-gold-tone-finish-swarovski-5452462.png",
    "name": "Crystalline Glam watch",
    "price": "404"
  },
  {
    "brand": "Swarovski Watches",
    "catalog": "swarovski",
    "categories": [
      "Watches"
    ],
    "currentInventory": 4,
    "description": "Full pave, metal bracelet watch",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/cocktail-watch--metal-bracelet--rose-gold-tone--rose-gold-tone-finish-swarovski-5519327.png",
    "name": "Cocktail Watch",
    "price": "420"
  },
  {
    "brand": "Swarovski Watches",
    "catalog": "swarovski",
    "categories": [
      "Watches"
    ],
    "currentInventory": 4,
    "description": "Leather strap, green chrono watch",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/octea-lux-chrono-watch--leather-strap--green--rose-gold-tone-finish-swarovski-5452498.png",
    "name": "Octea Lux Chrono watch",
    "price": "420"
  },
  {
    "brand": "Swarovski Watches",
    "catalog": "swarovski",
    "categories": [
      "Watches"
    ],
    "currentInventory": 4,
    "description": "Metal bracelet, blue, stainless steel cosmopolitan watch",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/cosmopolitan-watch--metal-bracelet--blue--stainless-steel-swarovski-5517790.png",
    "name": "Cosmopolitan watch",
    "price": "200"
  },
  {
    "brand": "Swarovski Watches",
    "catalog": "swarovski",
    "categories": [
      "Watches"
    ],
    "currentInventory": 4,
    "description": "Metal bracelet, rose gold-tone finish rock watch",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/dream-rock-watch--metal-bracelet--blue--rose-gold-tone-finish-swarovski-5519317.png",
    "name": "Dream Rock watch",
    "price": "300"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "New arrivals"
    ],
    "currentInventory": 4,
    "description": "Round cut, white, rhodium plated earrings",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/millenia-drop-earrings--round-cut--white--rhodium-plated-swarovski-5628351.png",
    "name": "Millenia drop earrings",
    "price": "110"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "New arrivals"
    ],
    "currentInventory": 4,
    "description": "Pear cut, yellow, drop earrings",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/millenia-drop-earrings--pear-cut--yellow--gold-tone-plated-swarovski-5619495.png",
    "name": "Millenia drop earrings",
    "price": "110"
  },
  {
    "brand": "Swarovski Bracelet",
    "catalog": "swarovski",
    "categories": [
      "New arrivals"
    ],
    "currentInventory": 4,
    "description": "Triangle cut, rhodium plated bracelet",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/ortyx-bracelet--triangle-cut--blue--rhodium-plated-swarovski-5614925.png",
    "name": "Ortyx bracelet",
    "price": "300"
  },
  {
    "brand": "Swarovski Bracelet",
    "catalog": "swarovski",
    "categories": [
      "New arrivals"
    ],
    "currentInventory": 4,
    "description": "Multicolored, gold-tone plated bracelet",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/gema-bracelet--multicolored--gold-tone-plated-swarovski-5614451.png",
    "name": "Gema bracelet",
    "price": "300"
  },
  {
    "brand": "Swarovski Earrings",
    "catalog": "swarovski",
    "categories": [
      "New arrivals"
    ],
    "currentInventory": 4,
    "description": "Asymmetrical, long, rhodium plated earrings",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/gema-drop-earrings--asymmetrical--long--multicolored--rhodium-plated-swarovski-5613740.png",
    "name": "Gema drop earrings",
    "price": "180"
  },
  {
    "brand": "Swarovski Decorations",
    "catalog": "swarovski",
    "categories": [
      "On sale"
    ],
    "currentInventory": 4,
    "description": "Jungle Beats blue parakeet paco",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/jungle-beats-blue-parakeet-paco-swarovski-5574519.png",
    "name": "Blue Parakeet",
    "price": "100"
  },
  {
    "brand": "Swarovski Decorations",
    "catalog": "swarovski",
    "categories": [
      "On sale"
    ],
    "currentInventory": 4,
    "description": "Jungle Beats Pineapple magnet, red",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/jungle-beats-pineapple-magnet--red--small-swarovski-5572157.png",
    "name": "Pineapple Magnet",
    "price": "20"
  },
  {
    "brand": "Swarovski Decorations",
    "catalog": "swarovski",
    "categories": [
      "On sale"
    ],
    "currentInventory": 4,
    "description": "Crystal Paradise Frogs",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/crystal-paradise-frogs-swarovski-5522680.png",
    "name": "Frogs",
    "price": "400"
  },
  {
    "brand": "Swarovski Decorations",
    "catalog": "swarovski",
    "categories": [
      "On sale"
    ],
    "currentInventory": 4,
    "description": "Jungle Beats Butterfly Magnet",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/jungle-beats-butterfly-magnet--violet--small-swarovski-5572153.png",
    "name": "Butterfly Magnet",
    "price": "20"
  },
  {
    "brand": "Swarovski Decorations",
    "catalog": "swarovski",
    "categories": [
      "On sale"
    ],
    "currentInventory": 4,
    "description": "Jungle Beats Decorative Box Andoki",
    "id": "p0025",
    "image": "https://content.zeotap.com/demo/images/jungle-beats-decorative-box-andoki--green-swarovski-5557810.png",
    "name": "Decorative Box",
    "price": "150"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Passform laut Hersteller: Skinny Fit, Hohe Leibhöhe, Schmales Bein, Zip-Fly-Verschluss, 5-Pocket-Style, Leder-Badge am Bund, Label-Detail vorn, Used-Waschung, Elastische Baumwollmischung",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/jeans1.jpg",
    "name": "CALVIN KLEIN - Skinny Jeans",
    "material": "Baumwolle",
    "price": "99.90"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader, kurzer Schnitt, Carmen-Ausschnitt, Kurze Ärmel, Silikonbeschichtung am Ausschnitt, Elastischer Bund am Saum, Logo auf der Vorderseite.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/top1.jpg",
    "name": "CALVIN KLEIN - Cropped-Top",
    "material": "Polyester",
    "price": "44.90"
  },
  {
    "brand": "Gucci",
    "catalog": "breuninger",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "A-Linie, Rundhalsausschnitt, Kurze Knopfleiste, Lange Ärmel, Elastische Bündchen an den Ärmelenden, Ungefütterte Verarbeitung, Florales Muster, Made in Italy",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/dress1.jpg",
    "name": "GUCCI - Seidenkleid",
    "material": "Seide",
    "price": "2200.00"
  },
  {
    "brand": "VERSACE",
    "catalog": "breuninger",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Kapuze mit Tunnelzug, Überschnittene Schultern, Rippbündchen an Ärmeln und Saum, French Terry, Colour-Blocking-Design, Barocco-Muster allover, Label-Wording, Streifenoptik an den Bündchen",
    "id": "p0026",
    "image": "https://content.zeotap.com/demo/images/hoodie4.jpg",
    "name": "VERSACE - Hoodie",
    "material": "Baumwolle",
    "price": "349.99"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Taillierter Schnitt, Stehkragen mit Druckknöpfen, Kapuze mit Tunnelzug, Schließt mit Zwei-Wege-Reißverschluss und verdeckter, Knopfleiste, Seitliche Pattentaschen mit Druckknopf, Weitenverstellbare Ärmelenden mit Druckknöpfen, Teilgefütterte Verarbeitung am Rücken und am Frontbeeich",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/jacket1.jpg",
    "name": "WOOLRICH - Parka Summer",
    "material": "Baumwolle",
    "price": "299.99"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Kapuze, Leicht überschnittene Schultern, Kängurutasche, Rippbündchen an Ärmeln und Saum, French Terry, Logo-Stitching auf der Front, Ösen unter den Ärmeln",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/hoodie1.jpg",
    "name": "WOOLRICH - Hoodie mit Logo",
    "material": "Baumwolle",
    "price": "140.00"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Passform laut Hersteller: Straight Fit, Reguläre Leibhöhe, Gerades Bein, Zip-Fly-Verschluss, 5-Pocket-Style, Leder-Badge am Bund, Destroyed-Effekte, Used-Waschung, Reine Baumwolle",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/jeans2.jpg",
    "name": "CALVIN KLEIN - Destroyed Jeans Regular Fit",
    "material": "Baumwolle",
    "price": "119.90"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Rundhalsausschnitt, Gerippte Qualität, Flammgarn-Optik, Label-Stitching auf Brusthöhe, Elastische Baumwollqualität",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/top2.jpg",
    "name": "CALVIN KLEIN - T-Shirt",
    "material": "Baumwolle",
    "price": "34.99"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Stehkragen mit Klettverschluss, Schließt mit Zwei-Wege-Reißverschluss, Seitliche Reißverschlusstaschen, Innentasche mit Reißverschluss, Elastische Bündchen an den Ärmelenden, Tunnelzug am Saum, Gestepptes Innenfutter, Füllung aus Daunen und Federn",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/jacket2.jpg",
    "name": "WOOLRICH - Daunenjacke BERING",
    "material": "Daunen",
    "price": "399,99"
  },
  {
    "brand": "Boss",
    "catalog": "breuninger",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Passform laut Hersteller: Slim Fit, Absteigendes Revers mit Knopfloch, AMF-Naht, Einreiher mit zwei Knöpfen, Leistentasche auf, Brusthöhe, Kissing-Buttons, Pattentaschen, Zwei Seitenschlitze",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/dress2.jpg",
    "name": "BOSS - Anzugsakko HUGE Slim Fit",
    "material": "Wolle",
    "price": "449.00"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Kapuze mit Tunnelzug, Überschnittene Schultern, Kängurutasche, Rippbündchen an Ärmeln und Saum, French Terry, Label-Stitching, Reine Baumwolle",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/hoodie2.jpg",
    "name": "WOOLRICH - Hoodie",
    "material": "Baumwolle",
    "price": "119.99"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Für Jungen, Passform laut Hersteller: Slim Fit, Schmal zulaufendes Bein, Zip-Fly-Verschluss, 5-Pocket-Style, Innen verstellbarer Bund, Label-Badge am Bund, Optische Sitzfalten, Used-Waschung",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/jeans3.jpg",
    "name": "CALVIN KLEIN - Jeans Slim Fit KIDS",
    "material": "Baumwolle",
    "price": "59.90"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Für Jungen, Gerader Schnitt, Logo-Stitching auf der Brust, Rippbündchen an den Ärmeln, Kurze Seitenschlitz",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/top3.jpg",
    "name": "CALVIN KLEIN - Poloshirt KIDS",
    "material": "Baumwolle",
    "price": "49.90"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Für Jungen, Zum Wenden, Gerader Schnitt, Stehkragen, Kapuze, Schließt mit Reißverschluss, Seitliche Eingrifftaschen mit Reißverschluss, Gummizug am Saum und den Ärmelenden, Gefütterte Verarbeitung, Glattes Innenfutter",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/jacket3.jpg",
    "name": "WOOLRICH - Jacke RYKER KIDS",
    "material": "Polyester",
    "price": "149.99"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Für Jungen, Gerader Schnitt, Reverskragen, Einreiher mit zwei Knöpfen, Aufgesetzte Taschen, Ungefütterte Verarbeitung, Jerseyqualität, Elastischer Baumwollmix",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/dress3.jpg",
    "name": "WOOLRICH - Sakko KIDS",
    "material": "Baumwolle",
    "price": "79.99"
  },
  {
    "brand": "Adidas",
    "catalog": "breuninger",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Für Mädchen und Jungen, Gerader Schnitt, Kapuze, Kängurutasche, Rippbündchen an Ärmeln und Saum, French Terry, Plakatives Label-Print auf der Front, Elastische Verarbeitung aus Baumwolle",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/hoodie3.jpg",
    "name": "ADIDAS - Hoodie KIDS",
    "material": "Baumwolle",
    "price": "45.00"
  },
  {
    "brand": "Schlossberg",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Satin Noblesse: reine, feinste Baumwolle, ca. 110 g/m2, Bestehend aus Bettbezug, Schließt mit Reißverschluss, Florales Muster, Trocknergeeignet, Kissenbezug separat erhältlich",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/bed1.jpg",
    "name": "SCHLOSSBERG - Bettdecke MAY",
    "material": "Baumwolle",
    "price": "269.90"
  },
  {
    "brand": "JOOP!",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Weiche Haptik, Doubleface-Verarbeitung, Gekettelte Zierstiche an den Kanten, Eingearbeitetes Label-Wording",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/plaid1.jpg",
    "name": "JOOP! - Plaid",
    "material": "Polyacryl",
    "price": "99.99"
  },
  {
    "brand": "JOOP!",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Walkfrottee, Saugfähig, Weich, flauschig, Double Face, Hautfreundlich, Trocknergeeignet, Handtuchschlaufe, Eingearbeitetes Label-Wording, Hergestellt in Deutschland, Reine Baumwolle",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/towel1.jpg",
    "name": "JOOP! - Handtuch CLASSIC DOUBLEFACE",
    "material": "Baumwolle",
    "price": "23.95"
  },
  {
    "brand": "JOOP!",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Mit Kapuze, Seitliche Eingrifftaschen, Tunnelzug auf Taillenhöhe, Schlaufe zum Aufhängen, Label-Stitching auf der Front, Griffige Haptik, Frotteequalität",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/robe1.jpg",
    "name": "JOOP! - Bademantel WTH",
    "material": "Baumwolle",
    "price": "119.90"
  },
  {
    "brand": "LEXINGTON",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Dieses Kuechenhandtuch aus Baumwolle hat einen markenexklusiven Aufdruck mit einer Bratpfanne und einem von der Kueche inspirierten Text in der Mitte. Zur Veredelung dieses Kuechenhandtuchs aus Baumwolle gehören garnfarbene Streifen an den Seiten und eine gefaltete Kante",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/blanket1.jpg",
    "name": "LEXINGTON - Handtuch",
    "material": "Baumwolle",
    "price": "19.00"
  },
  {
    "brand": "InStyle x Kayoom",
    "catalog": "breuninger",
    "categories": [
      "Home & Living"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Der nativ angehauchte Lovis Teppich im Ethno-Look. Durch die klassische Farbgebung in Weiß und Schwarz eignet sich der Teppich sowohl für moderne, als auch für klassische Einrichtungen. Mit seinen 22 mm ist der Hochflorteppich besonders weich und hat eine angenehme Haptik",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/carpet1.jpg",
    "name": "INSTYLE X KAYOOM - Teppich LOVIS 300",
    "material": "Polypropylen",
    "price": "39.00"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Passform laut Hersteller: Super Skinny Fit, Hohe Leibhöhe, Schmales Bein, Zip-Fly-Verschluss, 5-Pocket-Style, Leder-Badge am Bund, Label-Flag an der Front, Optische Sitzfalten, Dezente Destroyed-Effekte, Used-Waschung",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/jeans4.jpg",
    "name": "CALVIN KLEIN - Jeans",
    "material": "Baumwolle",
    "price": "69.99"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Gerader Schnitt, Rundhalsausschnitt, Logo-Wording, Elastische Baumwollqualität",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/top4.jpg",
    "name": "CALVIN KLEIN - Longsleeve",
    "material": "Baumwolle",
    "price": "34.99"
  },
  {
    "brand": "Woolrich",
    "catalog": "breuninger",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Taillierter Schnitt, Stehkragen, Schließt mit Zwei-Wege-Reißverschluss, Tunnelzug auf Taillenhöhe, Seitliche, nahtfeine, Reißverschlusstaschen, Gefütterte Verarbeitung, Gestepptes Innenfutter, Füllung aus Daunen und Federn, Label-Detail am Saum, Leichtgewichtige Verarbeitung",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/jacket4.jpg",
    "name": "WOOLRICH - Lightweight-Daunenjacke HIBISCUS",
    "material": "Baumwolle",
    "price": "299.99"
  },
  {
    "brand": "Calvin Klein",
    "catalog": "breuninger",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Rippstrick, A-Linie, Rundhalsausschnitt, Ärmellos, Ungefütterte Verarbeitung, Elastisches Taillenband mit Label-Wording, Fließende Optik, Oberteil aus Rippstrick, Rockteil aus glatter Qualität",
    "id": "p0025",
    "image": "https://content.zeotap.com/demo/images/dress4.jpg",
    "name": "CALVIN KLEIN - Kleid im Materialmix",
    "material": "Baumwolle",
    "price": "49.90"
  },

  {
    "brand": "pepperts",
    "catalog": "lidl",
    "categories": [
      "Kinder"
    ],
    "color": "gray",
    "currentInventory": 4,
    "description": "Angenehmer Tragekomfort durch hohen Baumwollanteil, Hergestellt mit recyceltem Material, Bequemer Gummibund mit Kordelzug zur Weitenregulierung",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/lidljogger3.webp",
    "name": "pepperts Jungen Sweathose",
    "material": "Polyester",
    "price": "7.99"
  },
  {
    "brand": "crivit",
    "catalog": "lidl",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "mit seitlichen Eingrifftaschen, Gummizugbund, Kordel und Bündchen am Beinabschluss",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/lidljogger2.webp",
    "name": "crivit Herren Sweathose",
    "material": "Baumwolle",
    "price": "9.99"
  },
  {
    "brand": "Dual",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "brown",
    "currentInventory": 4,
    "description": "Nostalgie Musikcenter mit Plattenspieler (33, 45, 78 U/Minute) CD-Player (MP3), Kassettendeck, UKW/MW-Radio, AUX-In und USB-Anschluss",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/lidldual.webp",
    "name": "Dual NR 4 Stereoanlage",
    "material": "Holz",
    "price": "170.97"
  },
  {
    "brand": "esmara",
    "catalog": "lidl",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Besonders weich und anschmiegsam.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/lidltop1.webp",
    "name": "esmara Damen Feinstrick Kleid",
    "material": "Baumwolle",
    "price": "8.99"
  },
  {
    "brand": "esmara",
    "catalog": "lidl",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Bequeme Lederdecksohle und leichte, flexible TPR-Laufsohle",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/lidlshoe1.webp",
    "name": "esmara Damen Stiefelett",
    "material": "Leder",
    "price": "19.99"
  },
  {
    "brand": "Dual",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "silver",
    "currentInventory": 4,
    "description": "PLL-UKW Radio mit Bluetooth zur kabellosen Musikübertragung via Smartphone und Tablet",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/lidldual1.webp",
    "name": "Dual NR 7",
    "material": "Aluminium",
    "price": "219.90"
  },
  {
    "brand": "AURIOL",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": " Animierte Wettersymbole mit Prognose und Temperaturwarnung, Luftdruck-Grafik und Einzelwerte der letzten 12 Stunden und Anzeige von Innenraumtemperatur und Mondphase ",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/lidlweather.webp",
    "name": "AURIOL Funkwetterstation",
    "material": "Polyacryl",
    "price": "24.99"
  },
  {
    "brand": "crivit",
    "catalog": "lidl",
    "categories": [
      "Damen"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "moderne Knöchellänge und extra hoher und breiter Bund",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/lidltights1.webp",
    "name": "crivit - Damen Funktionstights",
    "material": "Polyamid",
    "price": "8.99"
  },
  {
    "brand": "esmara",
    "catalog": "lidl",
    "categories": [
      "Damen"
    ],
    "color": "various",
    "currentInventory": 4,
    "description": "Optimale Passform durch das Elasthan mit moderne Knöchellänge und mit seitlichen Eingrifftaschen und Teilgummizug hinten ",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/lidljogger1.webp",
    "name": "esmara Damen Businessjogger",
    "material": "Viskose",
    "price": "9.99"
  },
  {
    "brand": "Apple",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "space grey",
    "currentInventory": 4,
    "description": "Das MacBook Pro bietet perfekte Leistung in den Bereichen Rechenpower,Grafik und maschinelles Lernen (ML),sowohl im Batterie- als auch im Netzbetrieb",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/lidlmac.webp",
    "name": "Apple MacBook Pro",
    "material": "Aluminium",
    "price": "3399.00"
  },
  {
    "brand": "SILVERCREST",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Kompatibel mit Fernseher, Stereoanlagen oder mobiles Streaming via Bluetooth®",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/lidlbar.webp",
    "name": "SILVERCREST Soundbar Stereo",
    "material": "Polyacryl",
    "price": "49.99"
  },
  {
    "brand": "Sharp",
    "catalog": "lidl",
    "categories": [
      "Multimedia"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "CD-Player, Tuner, Lautsprecher",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/lidlstereo.webp",
    "name": "Sharp »XL-B517D« HI-FI MIKRO-SYSTEM",
    "material": "Polyacryl",
    "price": "98.08"
  },
  {
    "brand": "esmara",
    "catalog": "lidl",
    "categories": [
      "Mode"
    ],
    "color": "dunkelblau",
    "currentInventory": 4,
    "description": "Im 5-Pocket-Style mit hohem Baumwollanteil",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/lidljeans1.webp",
    "name": "esmara Damen Jeans, Straight Fit,",
    "material": "Baumwolle",
    "price": "8.99"
  },
  {
    "brand": "LIVERGY",
    "catalog": "lidl",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Normale Leibhöhe mit hohem Baumwollanteil",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/lidljeans2.webp",
    "name": "LIVERGY Herren Jeans",
    "material": "Baumwolle",
    "price": "12.99"
  },
  {
    "brand": "Nobel League",
    "catalog": "lidl",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Körpernah geschnitten mit Bündchen an Ärmel- und Saumabschluss",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/lidlsweatshirt1.webp",
    "name": "Nobel League Herren Feinstrickpullover",
    "material": "Baumwolle",
    "price": "12.99"
  },
  {
    "brand": "LIVERGY",
    "catalog": "lidl",
    "categories": [
      "Herren"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Leger geschnitten, in hochwertiger Pikee-Qualität",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/lidlshirt1.webp",
    "name": "LIVERGY Herren Poloshirt",
    "material": "Baumwolle",
    "price": "9.99"
  },
  {
    "brand": "LIVERGY",
    "catalog": "lidl",
    "categories": [
      "Herren"
    ],
    "color": "red",
    "currentInventory": 4,
    "description": "Poloshir aus hochwertiger Pikee-Qualität, gerade geschnitten",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/lidlshirt2.webp",
    "name": "LIVERGY Herren Poloshirt,",
    "material": "Baumwolle",
    "price": "9.99"
  },
  {
    "brand": "pepperts",
    "catalog": "lidl",
    "categories": [
      "Kinder"
    ],
    "color": "blaue",
    "currentInventory": 4,
    "description": "Angenehmer Tragekomfort durch hohen Baumwollanteil und hergestellt aus 29% recyceltem Material",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/lidljeans3.webp",
    "name": "pepperts Jungen Sweatdenim",
    "material": "Baumwolle",
    "price": "9.99"
  },
  {
    "brand": "lupilu",
    "catalog": "lidl",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Matsch- und -Buddeljacke, wind- und wasserdicht mit reflektierende Deko-Elemente",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/lidljacket1.webp",
    "name": "lupilu Kleinkinder Mädchen Matsch- und Buddeljacke",
    "material": "Baumwolle",
    "price": "12.99"
  },
  {
    "brand": "lupilu",
    "catalog": "lidl",
    "categories": [
      "Kinder"
    ],
    "color": "blue",
    "currentInventory": 4,
    "description": "Besonders leicht und bequem mit leichter TPR-Laufsohle",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/lidlshoe2.webp",
    "name": "lupilu Kleinkinder Jungen Hausschuh",
    "material": "Baumwolle",
    "price": "4.99"
  },
  {
    "brand": "lupilu Kleinkinder Mädchen Strickkleid",
    "catalog": "lidl",
    "categories": [
      "Kinder"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Strickkleid mit Umhängetäschchen aus Strick aus reiner Baumwolle mit angenehmen Tragekomfort",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/lidldress1.webp",
    "name": "lupilu Kleinkinder Mädchen Strickkleid",
    "material": "Baumwolle",
    "price": "6.99"
  },
  {
    "brand": "lupilu",
    "catalog": "lidl",
    "categories": [
      "Sale"
    ],
    "color": "blue",
    "currentInventory": 4,
    "description": "Activity Center mit vielfältigen Funktionen, schult Tast- und Gehörsinn und fördert die Hand-Augen-Koordination. Zusammenklappbare Bögen zum einfachen Mitnehmen auch für unterwegs",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/lidlcenter.webp",
    "name": "lupilu Activity Center",
    "material": "Polyester",
    "price": "24.99"
  },
  {
    "brand": "SILVERCREST",
    "catalog": "lidl",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Smarte Steuerung via App, für Hartböden und kurzflorige Teppichböden, bequeme Reinigung auch schwer erreichbarer Stellen",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/lidlrobot.webp",
    "name": "SILVERCREST Saugroboter »SSRA1«",
    "material": "Polyacryl",
    "price": "99.99"
  },
  {
    "brand": "SILVERCREST",
    "catalog": "lidl",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "TKegelmahlwerk SKKM 200 A1 aus hochwertigem Edelstahl, Fassungsvermögen ca. 250 g Kaffeebohnen",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/lidlcoffee.webp",
    "name": "SILVERCREST Kaffeemühle",
    "material": "Glas",
    "price": "19.99"
  },
  {
    "brand": "ERNESTO",
    "catalog": "lidl",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Zum gesundheitsbewussten, fettarmen Braten, besonders langlebiges Edelstahldesign, energiesparendes, schonendes Kochen",
    "id": "p0025",
    "image": "https://content.zeotap.com/demo/images/lidlpan.webp",
    "name": "ERNESTO Edelstahlpfanne",
    "material": "Edelstahl",
    "price": "14.99"
  },
  {
    "brand": "ERNESTO",
    "catalog": "lidl",
    "categories": [
      "Sale"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "Hochwertiges Thermo-Glasgefäß mit praktischer Einhand-Ausgießtaste, Gehäuse aus Edelstahl",
    "id": "p0026",
    "image": "https://content.zeotap.com/demo/images/lidlthermo.webp",
    "name": "ERNESTO Isolierkanne",
    "material": "Edelstahl",
    "price": "6.99"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Women's Gifts"
    ],
    "color": "Dark Birch Brown",
    "currentInventory": 4,
    "description": "A slim crossbody bag in Exaggerated Check and topstitched leather, accented with polished logo-engraved press-studs and a detachable strap. Wear on the shoulder, across the body or carry as a clutch. The main material of this product is made with at least 30% bio-based materials. Bio-based materials are derived from renewable natural resources such as plants and castor oil.",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/burberry0001.webp",
    "name": "Exaggerated Check and Leather Note Bag",
    "material": "Leather",
    "price": "£1,150"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Women's Gifts"
    ],
    "color": "Honey",
    "currentInventory": 4,
    "description": "Our classic trench is updated with modern proportions. The archive-inspired style is crafted from bespoke gabardine containing organic cotton, developed for its crease-resistant structure and woven at the Burberry Mill in Yorkshire. Layer over knitwear and tailoring, and pop the collar to reveal a flash of Vintage Check.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/burberry0002.webp",
    "name": "The Long Kensington Heritage Trench Coat",
    "material": "Duschgel",
    "price": "£1,790"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Women's Gifts"
    ],
    "color": "Dark Birch Brown/Black",
    "currentInventory": 4,
    "description": "n Italian-made scarf in wool, referencing sports styles with our jacquard-woven logo and delicate eyelash fringing.",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/burberry0003.webp",
    "name": "Logo Wool Jacquard Scarf",
    "material": "Wool",
    "price": "£280"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Women's Gifts"
    ],
    "color": "Dark Birch Brown",
    "currentInventory": 4,
    "description": "An Italian-made belt in our Exaggerated Check, reversing to a solid colour in topstitched leather. The versatile style is secured with a gold-plated Thomas Burberry Monogram plaque buckle. The main material of this product is made with at least 30% bio-based materials. Bio-based materials are derived from renewable natural resources such as plants and castor oil.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/burberry0004.webp",
    "name": "Reversible Check and Leather TB Belt",
    "material": "Leather",
    "price": "£370"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Men"
    ],
    "color": "Storm Grey",
    "currentInventory": 4,
    "description": "A slim-fit shirt in stretch cotton poplin, reworked with an Exaggerated Check. The design is styled with a neat button-down collar and patch pocket at the chest.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/burberry0005.webp",
    "name": "Check Stretch Cotton Poplin Shirt",
    "material": "Cotton",
    "price": "£370"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Men"
    ],
    "color": "Storm Grey",
    "currentInventory": 4,
    "description": "Our chunky sneaker reworked in Burberry Check, suede and leather. The design is tagged with our logo and set on an accentuated, curved sole.",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/burberry0006.webp",
    "name": "Check, Suede and Leather Sneakers",
    "material": "Polyester, Suede",
    "price": "£550"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Men"
    ],
    "color": "Black",
    "currentInventory": 4,
    "description": "A puffer jacket in Italian-woven nylon, cut to an oversized fit and tagged with our outlined logo at the hem. The hooded design features detachable, adjustable interior straps so it can be carried on the back.",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/burberry0007.webp",
    "name": "Logo Print Nylon Oversized Hooded Puffer Jacket",
    "material": "Mask",
    "price": "£1,590"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Men"
    ],
    "color": "black",
    "currentInventory": 4,
    "description": "An Italian-made scarf in a cosy wool blend, woven with our lustrous lamé logo. The generous silhouette is finished with delicate eyelash fringing.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/burberry0008.webp",
    "name": "Logo Wool Blend Jacquard Scarf",
    "material": "Camel/Light Blush",
    "price": "£300"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Children"
    ],
    "color": "Archive Beige",
    "currentInventory": 4,
    "description": "A classic shirt in stretch cotton, panelled in a patchwork of contrasting Burberry Checks.",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/burberry0009.webp",
    "name": "Patchwork Check Stretch Cotton Shirt",
    "material": "Cotton",
    "price": "£230"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Children"
    ],
    "color": "Archive Beige",
    "currentInventory": 4,
    "description": "Slip-on sneakers containing organic cotton, panelled in contrasting Burberry Checks. The design is set on a chunky ridged sole and detailed with our logo.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/burberry0010.webp",
    "name": "Check Cotton Sneakers",
    "material": "Haarspülung",
    "price": "£250"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Children"
    ],
    "color": "Archive Beige",
    "currentInventory": 4,
    "description": "A protective car coat in cotton twill, inspired by our heritage styles. The design features a detachable hood and is lined in our Vintage Check.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/burberry0011.webp",
    "name": "Detachable Hood Cotton Twill Car Coat",
    "material": "Cotton, Polyester",
    "price": "£600"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Children"
    ],
    "color": "Archive Beige",
    "currentInventory": 4,
    "description": "A long-sleeved dress in stretch cotton, styled with a gathered skirt. The design is patterned with our Exaggerated Check.",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/burberry0012.webp",
    "name": "Long-sleeve Check Stretch Cotton Dress",
    "material": "Cotton, Elastane",
    "price": "£360"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Outerwear"
    ],
    "color": "Dark Charcoal Blue",
    "currentInventory": 4,
    "description": "A clean-lined car coat reimagined in an Italian-woven wool blend, tailored to a relaxed fit. The design is part of our outerwear 2022 campaign, celebrating adventures into the unknown and the spirit of exploration inherent in every Burberry garment.",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/burberry0013.webp",
    "name": "Wool Blend Car Coat",
    "material": "Cotton",
    "price": "£1,590"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Outerwear"
    ],
    "color": "Optic White",
    "currentInventory": 4,
    "description": "A funnel-neck silhouette in nylon, styled with a contrasting detachable hood highlighted with our outlined logo. The relaxed-fit style features an integrated snow skirt and is filled with goose down and feathers for added warmth. The design is part of our outerwear 2022 campaign, celebrating adventures into the unknown and the spirit of exploration inherent in every Burberry garment.",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/burberry0014.webp",
    "name": "Detachable Hood Nylon Puffer Jacket",
    "material": "Polyamide",
    "price": "£1,450"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Outerwear"
    ],
    "color": "White/Dark Charcoal Blue",
    "currentInventory": 4,
    "description": "A puffer jacket generously filled with goose down and feathers, animated in Night Check – mirroring the bright white stars nestled in a blanket of dark charcoal blue. Styled with a detachable hood, use the drawcord to cinch the silhouette at the waist. The design is part of our outerwear 2022 campaign, celebrating adventures into the unknown and the spirit of exploration inherent in every Burberry garment. The main material of this product is made with at least 70% recycled content.",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/burberry0015.webp",
    "name": "Detachable Hood Night Check Puffer Jacket",
    "material": "Polyester",
    "price": "£1,490"
  },
  {
    "brand": "Burberry",
    "catalog": "burberry",
    "categories": [
      "Outerwear"
    ],
    "color": "Deep Royal Blue",
    "currentInventory": 4,
    "description": "A nylon jacket styled with a drawcord hood and embroidered with our outlined logo. The design is cut to a relaxed fit and filled with insulating goose down and feathers.",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/burberry0016.webp",
    "name": "Embroidered Logo Nylon Hooded Jacket",
    "material": "Polyamide",
    "price": "£1,090"
  },

  // haba


  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Holzspielzeug"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Herzlich willkommen auf dem Bauernhof! Hier werden alle kleinen Fans des Landlebens in die Welt von Pferden, Kühen und Schafen entführt. Die Tiere, Bauer Tim und sein Traktor sind leicht zu greifen und können gut aufgestellt werden. Alle Spielfiguren sind liebevoll gestaltet und regen zu ersten Rollenspielen an. ",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/haba_bauernhof.avif",
    "name": "Spielwelt Bauernhof",
    "material": "Holz",
    "price": "54.99"
  },

  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kinderzimmer"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Herzlich willkommen im Zaubergarten! Hier gibt es magische Blumen auf grünen Wiesen und natürlich auch allerlei Tiere. Mitten im Zaubergarten steht ein magischer Pavillon – das HABA Spielzelt Zauberwald. Das Spielzelt Zauberwald ist schnell aufgebaut und mit seinen großzügigen Netzgewebe-Einsätzen ist es luftig und hell.",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/haba_wald.avif",
    "name": "Spielzelt Zauberwald",
    "material": "Polyester",
    "price": "99.99"
  },

  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Der knuffige Beißkerl Elefant von HABA kommt alles andere als schwerfällig daher: Er macht lustige Quietschgeräusche und auf dem Beißring mit genoppter Oberfläche findet Babys Kaumuskulatur Entspannung beim schmerzhaften Zahnen.",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/haba_ele.avif",
    "name": "Beisskerl Elefant",
    "material": "Baumwolle",
    "price": "5.99"
  },

  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Holzspielzeug"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Mit den abwechslungsreichen Stapelwürfeln Waldfreunde von HABA üben Kleinkinder das Ordnen nach Größe und lernen die Zahlen von 1 bis 10 kennen. Jeder der Stapelwürfel aus Pappe zeigt ein anderes niedliches Tier, eine andere Farbe und eine Zahl. Beim Bauen wächst an einer Seite des Stapelturms ab 1 Jahr ein prächtiger Baum in die Höhe.",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/haba_wurfel.avif",
    "name": "Stapelwuerfel Waldfreunde",
    "material": "Holz",
    "price": "19.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kugelbahnen"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Jede Menge Action gibt es auf der Kullerbü-Sound-Spielbahn Bauernhof von HABA. Schaf Selma und Schwein Simon sind mal wieder aus ihrem Stall ausgebüxt und sausen davon: Entweder flitzen sie auf die Wiese oder unter dem Glöckchen-Tor hindurch. Können die tierischen Ausreißer dem flinken Krangreifer entkommen?",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/haba_kugelb_bauernhof.avif",
    "name": "Spielbahn Bauernhof",
    "material": "Holz",
    "price": "149.99"
  },

  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Puppenwelt"],
    "color": "blau",
    "currentInventory": 4,
    "description": "Die Kuschelpuppe Philine ist mit ihren roten Haaren, blauen Augen und Sommersprossen eine fröhliche Begleiterin durch die Baby- und Kleinkindzeit. Mit ihrem weichen und anschmiegsamen Körper können Kinder traumhaft mit Philine schmusen und kuscheln.",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/haba_puppe_philine.avif",
    "name": "Puppe Philine",
    "material": "Baumwolle",
    "price": "19.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Holzspielzeug"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Das Stapelspielzeug Waldfreunde von HABA ist Puzzle-Vergnügen in 3D. Zehn doppelseitig bedruckte Vorlagenkarten fordern Kleinkinder ab 2 Jahre zum Puzzeln und Stapeln heraus. Aus den Bären, Eulen und Bäumen in verschiedenen Farben stapeln sie die abgebildeten Bauwerke. Wo darf die Eule sitzen? Welcher Bär steht ganz oben?",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/haba_stapel.avif",
    "name": "Stapelspielzeug Wald",
    "material": "Holz",
    "price": "27.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Puppenwelt"],
    "color": "gelb",
    "currentInventory": 4,
    "description": "Die HABA Minipuppe Schutzengel Nora ist eine fröhliche Stoffpuppe. Mit ihrem niedlichen Lächeln und dem gelben Kleid lädt die süße Puppe Babys ab 0 Monaten zum Schauen und Greifen ein. Sie ist besonders weich und prima zum Knuddeln. Größe und Proportionen der Stoffpuppe sind ideal für kleine Kinderhände. ",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/haba_puppe_nora.avif",
    "name": "Puppe Nora",
    "material": "Baumwolle",
    "price": "17.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Puppenwelt"],
    "color": "grau",
    "currentInventory": 4,
    "description": "Vor Wolf Grisbert von HABA braucht sich nun wirklich niemand zu fürchten. Egal ob nach Drehbuch oder im freien Spiel können bereits kleine Hände mit etwas Geschick die lustige Handpuppe zum Leben erwecken und jedes Puppenspiel gehörig durcheinanderwirbeln",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/haba_puppe_wolf.avif",
    "name": "Puppe Wolf",
    "material": "Baumwolle",
    "price": "22.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Puppenwelt"],
    "color": "rot",
    "currentInventory": 4,
    "description": "Die HABA Puppe William ist bekannt für ihre berühmte Spürnase. William ist sehr neugierig und möchte alles ganz genau wissen. Am liebsten begibt er sich auf die Suche nach spannenden Geheimnissen und löst Rätsel. Dabei trägt er sein geringeltes Sweatshirt und seine Freizeithose.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/haba_puppe_william.avif",
    "name": "Puppe William",
    "material": "Baumwolle",
    "price": "32.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Puppenwelt"],
    "color": "rot",
    "currentInventory": 4,
    "description": "Als weibliche Hauptfigur gehört Gretelchen in jedes gute Kasperletheaterstück. Die HABA Handpuppe ist liebevoll gestaltet mit niedlichem Lächeln und langen braunen Zöpfen. Große und kleine Puppenspieler erwecken sie in märchenhaften Geschichten zum Leben.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/haba_puppe_Gretchen.avif",
    "name": "Puppe Gretchen",
    "material": "Baumwolle",
    "price": "22.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kugelbahnen"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Der Bahnhof für die Kullerbü-Spielbahn von HABA ist eine große Spielkulisse für Kinder ab 2 Jahren. Über die abwechslungsreichen Holzschienen fährt ein moderner Schnellzug. Die drei Waggons lassen sich aufklappen, damit die drei Charakterkugeln ein- und aussteigen können. Lockführer Loris, Dina und Uli sind auf der Reise durch Kullerbü. Das Glöckchen signalisiert ihre Ankunft im Bahnhof. ",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/haba_kugelb_bahnhof.avif",
    "name": "Spieba Bahnhof",
    "material": "Holz",
    "price": "179.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kugelbahnen"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Eine HABA Kugelbahn ab 2 Jahren mit zwei spannenden Effekten: In der Kullerbü-Kombi Kugel-Stau rollen 3 Kugeln in den Kugelsammler. Wird der Hebel gelöst, rollen die aufgestauten Kugeln los und ab ins Strudel-Vergnügen. Der Kugeltrichter ist das reinste Karussell!",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/haba_kugelb_stau.avif",
    "name": "Kuba Kugel-Stau",
    "material": "Holz",
    "price": "84.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kugelbahnen"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Der Glöckchentunnel ist eine tolle Ergänzung für alle Kullerbü-Spielbahnen. Kugeln und Fahrzeuge rollen durch den Tunnelund bringen das Glöckchen zum Klingeln. Besonders praktisch: Die Tunnelbögen können als Träger für Brücken in die Spiellandschaften integriert werden.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/haba_kugelb_glocke.avif",
    "name": "Glockentunnel",
    "material": "Holz",
    "price": "32.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kugelbahnen"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Auf die Kegel, fertig, los! Auf der Kullerbü-Kugelbahn Kegelfarm geht es gar lustig zu, denn die Tiere treiben allerlei Schabernack. Die Kugelbahn von HABA ist besonders liebevoll und farbenfroh gestaltet – mit vielen Details, die Kinder ab 2 Jahren zum Entdecken einladen.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/haba_kugelb_kegelfarm.avif",
    "name": "Kugelbahn Kegelfarm",
    "material": "Holz",
    "price": "49.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kinderzimmer"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Ahoi! Willkommen an Board des Schaukel Schiffs! Auf dem Schaukel Schiff geht es gar lustig zu, denn die Matrosen treiben allerlei Schabernack. Das Schaukel Schiff von HABA ist besonders liebevoll und farbenfroh gestaltet – mit vielen Details, die Kinder ab 2 Jahren zum Entdecken einladen.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/haba_schaukel.avif",
    "name": "Schaukel Schiff",
    "material": "Baumwolle",
    "price": "269.00"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kinderzimmer"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Das liebevoll gestaltete Spielzelt Maerchenturm von HABA hat Turmmauern in Lila, ein zauberhaftes Dach in Rosa und Rot und ist wie im Maerchen von bluehenden Rosen umgeben. Hier werden Kinder zu märchenhaften Prinzessinnen, Prinzen, Rittern und Burgfraeulein.",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/haba_turm.avif",
    "name": "Schloss Turm",
    "material": "Baumwolle",
    "price": "119.00"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kinderzimmer"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Wie der Superheld auf der HABA-Zahndose fühlen sich Kinder mit ihrem ersten Wackelzahn. Ist dieser ausgefallen, sind sie besonders stolz auf ihre erste Zahnlücke. Denn mit einer Zahnlücke fühlen sie sich schon groß. Die eigenen Milchzähne sind für die Kleinen ein Schatz und für Erwachsene später eine schöne Erinnerung an ihre Kindheit. ",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/haba_blau.avif",
    "name": "Zahndose Superheld",
    "material": "Holz",
    "price": "5.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Kinderzimmer"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Die HABA-Zahndose Prinzessin ist der passende Aufbewahrungsort für einen großen Schatz kleiner Mädchen: den ersten Wackelzahn. So manche kleine Prinzessin fiebert diesem Ereignis entgegen. Denn der Zahnwechsel bedeutet für Kinder viel",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/haba_rosa.avif",
    "name": "Zahndose Prinzessin",
    "material": "Holz",
    "price": "5.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Der König der Tiere rasselt bei HABA und verschafft mit dem Beißring mit genoppter Oberfläche Entspannung für Babys Kaumuskulatur und Hilfe beim schmerzhaften Zahnen. Der Beißkerl Löwe ist, wie alle anderen Greiflinge und Spielzeuge von HABA, frei von Schadstoffen und völlig unbedenklich.",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/haba_loewe.avif",
    "name": "Beisskerl Loewe",
    "material": "Baumwolle",
    "price": "5.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Der knuffige Beißkerl Elefant von HABA kommt alles andere als schwerfällig daher: Er macht lustige Quietschgeräusche und auf dem Beißring mit genoppter Oberfläche findet Babys Kaumuskulatur Entspannung beim schmerzhaften Zahnen.",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/haba_ele.avif",
    "name": "Beisskerl Elefant",
    "material": "Baumwolle",
    "price": "5.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Wenn Babys ihren Schnuller brauchen, will man nicht lange danach suchen. Mit der bunten HABA Schnullerkette Schutzengel ist das auch nicht mehr notwendig. Der praktische Clip hält den Schnuller immer dort, wo er hingehört.",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/haba_kette.avif",
    "name": "Schnullerkette Schutzengel",
    "material": "Polyacryl",
    "price": "12.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Heute machen wir eine Zeitreise zu den Dinosauriern! Spannende Abenteuer mit den Helden der Urzeit können Minis ab 1 Jahr in diesem schönen Babybuch von HABA erleben. Die stabilen Seiten aus Holz sind gut zu greifen und begeistern durch ihre besondere Haptik und Stabilität.",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/haba_buch.avif",
    "name": "Holz-Babybuch Dinos",
    "material": "Holz",
    "price": "14.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Babys"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Mit HABA muss niemand Angst vor wilden Tieren haben. Friedlich teilen sich Löwe, Giraffen, Elefant, Krokodil & Co. das niedliche Stoffbuch und geben kleinen Bilderbuch-Fans erste Einblicke in die Tierwelt. Der Knistereffekt in den Seiten lässt Babys aufhorchen und staunen.",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/haba_tiere.avif",
    "name": "Stoffbuch Wilde Tiere",
    "material": "Baumwolle",
    "price": "17.99"
  },
  {
    "brand": "HABA",
    "catalog": "haba",
    "categories": ["Holzspielzeug"],
    "color": "bunt",
    "currentInventory": 4,
    "description": "Ein Krokodil macht Musik? Das klingt ja lustig! Genau: die Kolbenflöte Krokodil von HABA bringt ganz besonderen Klangzauber ins Kinderzimmer. Bläst man in die Holzflöte hinein, erklingt ein Ton. Durch Ziehen und Drücken des Stabs verändert sich der Ton, wird höher oder tiefer.",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/haba_kroko.avif",
    "name": "Kolbenfloete Krokodil",
    "material": "Holz",
    "price": "17.99"
  },
  {
    "brand": "Laundry Baskets",
    "catalog": "H&M",
    "categories": ["H&M Home"],
    "currentInventory": 4,
    "description": "Large laundry basket in braided seagrass with a lid. Lined with a bag in a cotton weave that has a drawstring at the top for easy closure when the basket is full. Diameter 35 cm. Height 45 cm",
    "id": "p0019",
    "image": "https://content.zeotap.com/demo/images/Seagrasslaundrybasket.jpeg",
    "name": "Seagrass laundry basket",
    "price": "29.99"
  },
  {
    "brand": "Bedding",
    "catalog": "H&M",
    "categories": ["H&M Home"],
    "currentInventory": 4,
    "description": "Striped double/king duvet cover set in a cotton weave with a thread count of 144. Two pillowcases with an envelope opening",
    "id": "p0020",
    "image": "https://content.zeotap.com/demo/images/Cottondoublekingduvetcoverset.webp",
    "name": "Cotton double/king duvet cover set",
    "price": "39.99"
  },
  {
    "brand": "Dresses",
    "catalog": "H&M",
    "categories": ["Women"],
    "currentInventory": 4,
    "description": "Studio Collection. Floor-sweeping bandeau dress in tulle with a gathered corset bodice that has concealed silicone tape at the top, boning for support, and a concealed zip and hook-and-eye fastening at one side.",
    "id": "p0005",
    "image": "https://content.zeotap.com/demo/images/Voluminoustulledress.webp",
    "name": "Voluminous tulle dress",
    "price": "279.99"
  },
  {
    "brand": "Decorations",
    "catalog": "H&M",
    "categories": ["H&M Home"],
    "currentInventory": 4,
    "description": "Vase in fluted glass. Diameter at the top 16 cm. Height 21 cm",
    "id": "p0021",
    "image": "https://content.zeotap.com/demo/images/Glassvase.jpeg",
    "name": "Glass vase",
    "price": "24.99"
  },
  {
    "brand": "Decorations",
    "catalog": "H&M",
    "categories": ["H&M Home"],
    "currentInventory": 4,
    "description": "Mid plate in porcelain with a colourful all-over print. Diameter 21 cm",
    "id": "p0022",
    "image": "https://content.zeotap.com/demo/images/Porcelainmidplate.webp",
    "name": "Porcelain mid plate",
    "price": "6.99"
  },
  {
    "brand": "Dresses",
    "catalog": "H&M",
    "categories": ["Women"],
    "currentInventory": 4,
    "description": "Calf-length, fitted tube dress in heavy, ribbed jersey with a concealed silicone trim at the top to keep it in place",
    "id": "p0001",
    "image": "https://content.zeotap.com/demo/images/Ribbedtubedress.webp",
    "name": "Ribbed tube dress",
    "price": "18.99"
  },
  {
    "brand": "Cardigans",
    "catalog": "H&M",
    "categories": ["Women"],
    "currentInventory": 4,
    "description": "Fitted bolero with long sleeves. Strappy top featuring a V-shaped neckline with a wrapover section at the front. Double shoulder straps, one pair cross at the back, and a narrow tie belt at the waist.",
    "id": "p0002",
    "image": "https://content.zeotap.com/demo/images/2-piecerib-knitboleroandtopset.webp",
    "name": "2-piece rib-knit bolero and top set",
    "price": "24.99"
  },
  {
    "brand": "Blazers",
    "catalog": "H&M",
    "categories": ["Women"],
    "currentInventory": 4,
    "description": "Slightly longer, fitted blazer in a linen and cotton weave. Notch lapels and a double-breasted front with one visible and one concealed button",
    "id": "p0003",
    "image": "https://content.zeotap.com/demo/images/Waistedlinen-blendblazer.webp",
    "name": "Waisted linen-blend blazer",
    "price": "37.99"
  },
  {
    "brand": "Dresses",
    "catalog": "H&M",
    "categories": ["Women"],
    "currentInventory": 4,
    "description": "Dress in a viscose weave with a collar and concealed buttons at the top. Long raglan sleeves with buttoned cuffs and a gently flared skirt with gathered tiers.",
    "id": "p0004",
    "image": "https://content.zeotap.com/demo/images/Viscoseshirtdress.webp",
    "name": "Viscose shirt dress",
    "price": "19.99"
  },
  {
    "brand": "Trousers",
    "catalog": "H&M",
    "categories": ["Men"],
    "currentInventory": 4,
    "description": "Suit trousers in an airy linen weave with a zip fly and an extended waistband with a button and hook-and-eye fastening. Diagonal front pockets and jetted back pockets with a button.",
    "id": "p0006",
    "image": "https://content.zeotap.com/demo/images/RelaxedFitLinensuittrousers.webp",
    "name": "Relaxed Fit Linen suit trousers",
    "price": "44.99"
  },
  {
    "brand": "Shirts",
    "catalog": "H&M",
    "categories": ["Men"],
    "currentInventory": 4,
    "description": "Regular-fit shirt in Oxford cotton with a button-down collar, classic front, yoke at the back and an open chest pocket. Long sleeves with buttoned cuffs and a sleeve placket with a link button. Gently rounded hem.",
    "id": "p0007",
    "image": "https://content.zeotap.com/demo/images/RegularFitOxfordshirt.webp",
    "name": "Regular Fit Oxford shirt",
    "price": "14.99"
  },
  {
    "brand": "Swimwear",
    "catalog": "H&M",
    "categories": ["Men"],
    "currentInventory": 4,
    "description": "Swim shorts in woven fabric with an elasticated, drawstring waist, side pockets and a back pocket with a hook and loop fastening. Mesh inner shorts.",
    "id": "p0008",
    "image": "https://content.zeotap.com/demo/images/Swimshorts.jpeg",
    "name": "Swim shorts",
    "price": "18.99"
  },
  {
    "brand": "Jumpers",
    "catalog": "H&M",
    "categories": ["Men"],
    "currentInventory": 4,
    "description": "Regular-fit jumper in a soft knit containing some wool with a ribbed polo neck, long sleeves and ribbing at the cuffs and hem.",
    "id": "p0009",
    "image": "https://content.zeotap.com/demo/images/RegularFitPolo-neckjumper.webp",
    "name": "Regular Fit Polo-neck jumper",
    "price": "37.99"
  },
  {
    "brand": "Jumpers",
    "catalog": "H&M",
    "categories": ["Men"],
    "currentInventory": 4,
    "description": "Jumper in hole-knit cotton with a round neckline, long sleeves and ribbing around the neckline, cuffs and hem. Regular fit for comfortable wear and a classic silhouette.",
    "id": "p0010",
    "image": "https://content.zeotap.com/demo/images/RegularFitHole-knitjumper.webp",
    "name": "Regular Fit Hole-knit jumper",
    "price": "37.99"
  },
  {
    "brand": "Baby Boys Clothing",
    "catalog": "H&M",
    "categories": ["Baby"],
    "currentInventory": 4,
    "description": "Set with a T-shirt and pair of shorts in waffled cotton jersey. T-shirt with a concealed press-stud on one shoulder (except in sizes 2-4Y). Shorts with soft elastication at the waist.",
    "id": "p0011",
    "image": "https://content.zeotap.com/demo/images/2-piecewaffledcottonset.jpeg",
    "name": "2-piece waffled cotton set",
    "price": "8.99"
  },
  {
    "brand": "Baby Boys Accessories",
    "catalog": "H&M",
    "categories": ["Baby"],
    "currentInventory": 4,
    "description": "Sun hat in an airy cotton weave with a wavy, frill-trimmed brim and ties under the chin. Lined",
    "id": "p0012",
    "image": "https://content.zeotap.com/demo/images/Cottonsunhat.jpeg",
    "name": "Cotton sun hat",
    "price": "3.99"
  },
  {
    "brand": "Baby Boys Clothing",
    "catalog": "H&M",
    "categories": ["Baby"],
    "currentInventory": 4,
    "description": "T-shirts in soft cotton jersey with a ribbed trim around the neckline and press-stud on one shoulder (except in sizes 2–4Y)",
    "id": "p0013",
    "image": "https://content.zeotap.com/demo/images/2-packcottonT-shirts.jpeg",
    "name": "2-pack cotton T-shirts",
    "price": "7.99"
  },
  {
    "brand": "Baby Boys Clothing",
    "catalog": "H&M",
    "categories": ["Baby"],
    "currentInventory": 4,
    "description": "Baby Exclusive. Set with a sleeveless top and a matching pair of shorts in pointelle-knit cotton. Top with a square neckline, ribbed shoulder straps with a frill trim that continues around the armholes, and a gently scalloped hem. Shorts with an adjustable, elasticated waist and gently scalloped hems",
    "id": "p0014",
    "image": "https://content.zeotap.com/demo/images/2-piecepointelle-knitset.jpeg",
    "name": "2-piece pointelle-knit set",
    "price": "18.99"
  },
  {
    "brand": "T-Shirts",
    "catalog": "H&M",
    "categories": ["Kids"],
    "currentInventory": 4,
    "description": "Top in soft cotton jersey featuring a round neckline and short butterfly sleeves in woven fabric with broderie anglaise",
    "id": "p0015",
    "image": "https://content.zeotap.com/demo/images/Jerseytopwithbroderieanglaise.webp",
    "name": "Jersey top with broderie anglaise",
    "price": "2.99"
  },
  {
    "brand": "T-Shirts",
    "catalog": "H&M",
    "categories": ["Kids"],
    "currentInventory": 4,
    "description": "Top in soft, ribbed jersey with a round neckline and short sleeves with an overlocked edge at the cuffs",
    "id": "p0016",
    "image": "https://content.zeotap.com/demo/images/Ribbedjerseytop.jpeg",
    "name": "Ribbed jersey top",
    "price": "3.99"
  },
  {
    "brand": "T-Shirts",
    "catalog": "H&M",
    "categories": ["Kids"],
    "currentInventory": 4,
    "description": "Comfy set with a T-shirt and a pair of shorts. T-shirt in cotton jersey with dropped shoulders, ribbing around the neckline and a straight-cut hem. Shorts in lightweight sweatshirt fabric with an elasticated, drawstring waist and pockets in the side seams",
    "id": "p0017",
    "image": "https://content.zeotap.com/demo/images/2-pieceset.jpeg",
    "name": "2-piece set",
    "price": "8.99"
  },
  {
    "brand": "Trousers",
    "catalog": "H&M",
    "categories": ["Kids"],
    "currentInventory": 4,
    "description": "Joggers in cotton twill with an elasticated drawstring waist, a fake fly, diagonal side pockets and pleats at the knees. Straight legs with elasticated hems",
    "id": "p0018",
    "image": "https://content.zeotap.com/demo/images/Twilljoggers.webp",
    "name": "Twill joggers",
    "price": "7.99"
  },
  {
    "brand": "Makeup",
    "catalog": "H&M",
    "categories": ["Beauty"],
    "currentInventory": 4,
    "description": "Rounded brush with soft, dense bristles for buildable coverage and even application. Wooden handle and vegan, synthetic bristles",
    "id": "p0023",
    "image": "https://content.zeotap.com/demo/images/Buffingfoundationbrush.jpeg",
    "name": "Buffing foundation brush",
    "price": "6.99"
  },
  {
    "brand": "Makeup",
    "catalog": "H&M",
    "categories": ["Beauty"],
    "currentInventory": 4,
    "description": "Slim, rectangular make-up bag with a two-way zip around the lid. Lined. Height 5 cm. Width 20.5 cm. Depth 12.5 cm.",
    "id": "p0024",
    "image": "https://content.zeotap.com/demo/images/Make-upbag.webp",
    "name": "Make-up bag",
    "price": "8.99"
  },
  {
    "brand": "Makeup",
    "catalog": "H&M",
    "categories": ["Beauty"],
    "currentInventory": 4,
    "description": "Limited Edition. Fashion-focused nail polish in a gorgeous array of inspiring colours and effects. Application is quick and easy – one coat of the high-gloss formula provides a rich, flawless finish. 8 ml",
    "id": "p0025",
    "image": "https://content.zeotap.com/demo/images/Nailpolish.webp",
    "name": "Nail polish",
    "price": "3.99"
  }
]
// inventory.map(i => {
//   i.id = uuid()
//   return i
// })

export default inventory