import {nanoid} from 'nanoid'

export const isBrowser = () => typeof window !== 'undefined';

export const isConfigSet = () => !!window.zeotapConfig;

// export const initUser = () => {
//    if( isBrowser() ){
//          window.zeotap.init(window.zeotapConfig.wk,window.zeotapConfig)
//     }
// };

export const setUserProperties = (properties = {}) => {
    if (isBrowser() && isConfigSet()) {
        if (window.zeotapConfig.gtm) {
            window.dataLayer && window.dataLayer.push({event:"setUserProperties",...properties});
        }
        if (window.zeotapConfig.adobe) {
            window._satellite.track("setUserProperties", {...properties});
        }
        if (window.zeotapConfig.rudderstack) {
            window.rudderanalytics.track("setUserProperties", properties);
        }
        if (!properties.id) {
            properties.id = nanoid();
        }
        window.zeotap && window.zeotap.setUserProperties && window.zeotap.setUserProperties(properties);
    }
};

// export const setAdobeParams = (fpuid) => {
//   if (
//     window.adobe &&
//     window.adobe.target &&
//     typeof window.adobe.target.getOffers === "function"
//   ) {
//     const req = {
//       request: {
//         execute: {
//           id: {
//             thirdPartyId: fpuid
//           },
//           mboxes: [
//             {
//               index: 0,
//               name: "test-mbox",
//             },
//           ],
//         },
//       },
//     }

//     var offers = window.adobe.target.getOffers(req).then(response => {})
//   }
//   return
// }

export const setUserIdentities = (properties,isHashed=false) => {
    //window._satellite.track('setUserIdentities')
    if (isBrowser() && isConfigSet()) {
        if (window.zeotapConfig.gtm) {
            window.dataLayer && window.dataLayer.push({event:"setUserIdentities",isHashed,...properties});
            // if (properties.fpuid) {
            //   setAdobeParams(properties.fpuid)
            // }
        }
        if (window.zeotapConfig.rudderstack) {
            window.rudderanalytics.identify(window.rudderanalytics.getAnonymousId(), properties);
        }
        if (window.zeotapConfig.adobe) {
          window._satellite.track("setUserIdentities", {
              ...properties,
              quantcastVendorId: true,
              amazonVendorId: true,
              appnexusVendorConsent: true,
              googleVendorConsent: true,
              marketingCloudVisitorId: "12345678901234567890",
              xandrVendorConsent: true 
            });
        }
        else {
            window.zeotap && window.zeotap.setUserIdentities && window.zeotap.setUserIdentities(properties,isHashed);
            // if (properties.fpuid) {
            //   setAdobeParams(properties.fpuid)
            // }
        }
    }
}

export const unsetUserIdentities = (...properties) => { 
    if (isBrowser() && isConfigSet()) {
        if (window.zeotapConfig.gtm) {
            window.dataLayer && window.dataLayer.push({event:"unsetUserIdentities",...properties});
        }
        if (window.zeotapConfig.rudderstack) {
            window.rudderanalytics.reset();
        }
        if (window.zeotapConfig.adobe) {
            window._satellite.track("unsetUserIdentities", {...properties});
        }
        else {
            window.zeotap && window.zeotap.unsetUserIdentities && window.zeotap.unsetUserIdentities(...properties);
        } 
    }
}

export const setZI = (value) => { isBrowser() && isConfigSet() && !window.zeotapConfig.gtm && window.zeotap && window.zeotap.setZI && window.zeotap.setZI(value) };

export const resetZI = () =>  { isBrowser() && isConfigSet() && !window.zeotapConfig.gtm && window.zeotap && window.zeotap.resetZI && window.zeotap.resetZI() };

export const getZI = () =>  { isBrowser() && isConfigSet() && !window.zeotapConfig.gtm && window.zeotap && window.zeotap.getZI && window.zeotap.getZI() };

export const setEventProperties = (eventType, eventProperties = {}) => {
    if (isBrowser() && isConfigSet()) {
        if (window.zeotapConfig.gtm) {
            window.dataLayer && window.dataLayer.push({event: eventType,...eventProperties});
        }
        if (window.zeotapConfig.rudderstack) {
            window.rudderanalytics.track(eventType, eventProperties);
        }
        if (window.zeotapConfig.adobe) {
            window._satellite.track('setEventProperties', {
                ...eventProperties, 
                test1: 'test1',
                test2: 'test2',
                test3: 'test3',
                test4: 'test4',
                test5: 'test5',
                test6: 'test6',
                test7: 'test7'
            });
        }
        else {
            if (!eventProperties.id) {
                eventProperties.id = nanoid();
            }
            window.zeotap && window.zeotap.setEventProperties && window.zeotap.setEventProperties(eventType, eventProperties);
        }
    }
};


export const setPageProperties = (pageProperties = {}) => { 
    if (isBrowser() && isConfigSet()) {
        if (window.zeotapConfig.gtm) {
            window.dataLayer && window.dataLayer.push({event:"setPageProperties",...pageProperties});
        }
        if (window.zeotapConfig.rudderstack) {
            window.rudderanalytics.page(pageProperties.page_category, "setPageProperties", pageProperties);
        }
        if (window.zeotapConfig.adobe) {
            window._satellite.track("setPageProperties", {
                ...pageProperties, 
                test1: 'test1',
                test2: 'test2',
                test3: 'test3',
                test4: 'test4',
                test5: 'test5',
                test6: 'test6',
                test7: 'test7'
            });
        }
         else {
            if (!pageProperties.id) {
                pageProperties.id = nanoid();
            }
            window.zeotap && window.zeotap.setPageProperties && window.zeotap.setPageProperties(pageProperties);
        }
    }
}

export const setMetaProperties = (metaProperties) => { isBrowser() && isConfigSet() && !window.zeotapConfig.gtm && window.zeotap && window.zeotap.setMetaProperties && window.zeotap.setMetaProperties(metaProperties) };
