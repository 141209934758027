import React from 'react'
import { getTrimmedString, getSiteConfigField } from '../../../utils/helpers'
import { setEventProperties } from '../../../utils/zeotap'
import Image from '../Image'
import ConditionalLink from '../ConditionalLink'

const DisplaySmall = ({ link, title, subtitle, imageSrc, id }) =>  (
  <div className="lg:w-flex-fourth bg-light
  px-6 pt-10 pb-2 lg:p-6 lg:pb-0
  hover:bg-light-200 lg:mb-0 mb-4">
    <ConditionalLink redirectUrl={`/${link}`} onClick={()=>setEventProperties('viewProduct',{productID: id})} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
      <div className="flex flex-column justify-center items-center">
        <Image alt={title} src={imageSrc} className="w-3/5 showcase" />
      </div>
      <div className="">
        <p className="text-xl font-semibold mb-1">{title}</p>
        <p className="text-xs text-gray-700 mb-4">{getTrimmedString(subtitle, 150)}</p>
      </div>
    </ConditionalLink>
  </div>
)

export default DisplaySmall