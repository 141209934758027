import { isBrowser } from './zeotap';
import { availableConfigs } from './const';
import { compose, pluck, reduce, uniq } from 'ramda';

function getSessionStoredJSON(key){
  if(isBrowser() && window.sessionStorage){
      return window.sessionStorage.getItem(key) ? JSON.parse(window.sessionStorage.getItem(key)) : ''
  }
  return '';
}
function isSymphony(){const configdetails = getSessionStoredJSON("configString")['sdkConfigDetails']; return configdetails ? !!configdetails['symphony'] : false ;}
function slugify(string) {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

function titleIfy(slug) {
  var words = slug.split('-');
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');
}

function getTrimmedString(string, length = 8) {
  if (string.length <= length) {
    return string
  } else {
    return string.substring(0, length) + '...'
  }
}

function getLocalStoredString(key){
  if(isBrowser() && window.localStorage && window.localStorage.getItem(key)){
    return window.localStorage.getItem(key)
  }
  return null;
}


const getSelectedConfig = () => {
  return getSessionStoredJSON("configString")['selectedConfig'] || availableConfigs.zeotap;
}

const getSiteConfigField = (field) => {
  const siteConfig = isBrowser() && window.siteConfig;
  return (!!siteConfig && siteConfig.fields[field].value);
}


const getCatalogFilteredItems = (items) => items.filter(item => item.catalog === (getSiteConfigField('productCatalog') || 'furniture')); 

const getProductCatalogOptions = compose(reduce((acc, curr) => {acc.push({name: 'productCatalog', value: curr, label: curr.charAt(0).toUpperCase() + curr.slice(1)}); return acc;}, []),uniq, pluck('catalog') )

const setFontFace = (url) => {
  const fontFamilyTag = document.getElementById('my-font');
  if(!fontFamilyTag) {
    var newStyle = document.createElement('style');
    newStyle.id="my-font"
    newStyle.appendChild(document.createTextNode("\
    @font-face {\
        font-family: my-font;\
        src: url('" + url + "');\
    }\
    "));
    document.head.appendChild(newStyle);
  }
}

const setBgColor = (bgColor) => {
  if (document.body.style.background !== bgColor){
    document.body.style.background = bgColor;
  }
}
const setNavBarBgColor = (bgColor) => {
  const navElement = document.getElementById('nav');
  if (!!navElement && navElement.style.background !== bgColor){
    navElement.style.background = bgColor;
  }
}

const getObjectWhoseKeysStartWithPrefix = (object, prefix) => {
  return Object.entries(object).reduce((acc, [k,v]) => {
    if(k.startsWith(prefix))
      {
        acc[k] = v;
      }
    return acc;
  }, {})
}

export {
  getObjectWhoseKeysStartWithPrefix, slugify, titleIfy, getTrimmedString, getLocalStoredString, isSymphony, getSessionStoredJSON, getSelectedConfig, getCatalogFilteredItems, getProductCatalogOptions, getSiteConfigField, setFontFace, setBgColor, setNavBarBgColor
}