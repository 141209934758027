/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* global adobe */
import { Link } from "gatsby"
import React from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { slugify, titleIfy } from "../../utils/helpers"
import { isBrowser, setEventProperties } from "../../utils/zeotap"
import { ContextProviderComponent, SiteContext } from "../context/mainContext"
import { colors } from "../theme"
import inventory from "../../providers/inventory"
import DisplaySmall from "../components/heroComponents/DisplaySmall"
import { FaLongArrowAltRight } from "react-icons/fa"
import { GrFormSearch } from "react-icons/gr"
import {
  isSymphony,
  getCatalogFilteredItems,
  getSiteConfigField,
} from "../../utils/helpers"
import ConditionalLink from "../components/ConditionalLink"
const STORAGE_KEY = "GATSBY_ECOMMERCE_STARTER_"
const getCartCount = () => {
  if (typeof window !== "undefined") {
    try {
      const storageState = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
      const { cart } = storageState
      return cart.length
    } catch (err) { }
  }
  return 0
}

const getProductMap = () => {
  return getCatalogFilteredItems(inventory).reduce((acc, curr) => {
    return {
      ...acc,
      ...{ [curr.id]: curr },
    }
  }, {})
}

const getRecommendationApi = () => {
  var config = !!window.zeotapConfig ? window.zeotapConfig : {}
  switch (config.v) {
    case 'qa': return "https://unity-qa.zeotap.com/datamanager/api/v1/internal/symphony/recommendation";
    case 'staging': return "https://unity-staging.zeotap.com/datamanager/api/v1/internal/symphony/recommendation";
    case 'demo-prod': return "https://unity-demo.zeotap.com/datamanager/api/v1/internal/symphony/recommendation";
    case 'demo-qa': return "https://unity-demo-qa.zeotap.com/datamanager/api/v1/internal/symphony/recommendation"
    default: return "https://unity.zeotap.com/datamanager/api/v1/internal/symphony/recommendation"
  }
}

toast.configure({
  progressStyle: {
    background: colors.primary,
  },
})

class Layout extends React.Component {
  state = {
    recommendation: [],
  }
  productMap = {}
  recommendationPoller = null
  constructor(props) {
    super(props)
    this.fetchRecommendation = this.fetchRecommendation.bind(this)
    this.getProductRecommendations = this.getProductRecommendations.bind(this)
    this.productMap = getProductMap()
  }

  resetGDPR = () => {
    if (isBrowser()) {
      window.localStorage.removeItem("CONSENT")
      if (window.Didomi) window.Didomi.reset()
      window.location.href = "/"
    }
    return
  }

  clearStorage = () => {
    if (isBrowser()) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      if (window.Didomi) window.Didomi.reset()
      window.location.href = "/"
    }
    return
  }

  componentWillUnmount() {
    clearInterval(this.recommendationPoller)
  }

  fetchRecommendation() {
    var scope = this
    fetch(getRecommendationApi(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailSha256: window.localStorage.getItem("EMAIL_SHA256"),
        userId: !!window.zeotap && window.zeotap.getZI(),
        orgId: !!window.zeotapConfig && window.zeotapConfig.orgId,
      }),
    })
      .then(res => {
        if (res.status === 200) return res.json()
        else throw new Error("Something unexpected happened")
      })
      .then(res => {
        if (res) {
          scope.setState({
            recommendation: res,
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  getProductRecommendations(products = []) {
    var recommendedProducts =
      products && products.length > 0
        ? products
          .filter(
            v => !!v.recommended_productId && v.recommended_productId !== null && this.productMap[v.recommended_productId]
          )
          .map(m => ({
            ...this.productMap[m.recommended_productId],
            subText: m.recommendation,
          }))
        : []
    if (recommendedProducts.length === 0) {
      return null
    }
    recommendedProducts = recommendedProducts.slice(0, 5)
    return (
      <div>
        <div className="pt-10 pb-6 flex flex-col items-center">
          <h2 className="text-4xl mb-3">Check out these recommendations</h2>
        </div>
        <div className="recommendation-row">
          {recommendedProducts.map(product => (
            <DisplaySmall
              key={product.id}
              link={slugify(product.name)}
              title={product.name}
              subtitle={product.subText ? product.subText : ""}
              imageSrc={product.image}
              id={product.id}
            ></DisplaySmall>
          ))}
        </div>
      </div>
    )
  }

  getActionRecommendation(recommendations = [], links = []) {
    var cartCount = getCartCount()
    var _recommendations = recommendations.filter(v => v.recommendation)
    var recommendedActions =
      _recommendations && _recommendations.length > 0 ? _recommendations : []
    if (recommendedActions.length > 0) {
      var action = recommendedActions[0]
      var actionLinkMap = {
        addToCart: "/checkout",
        checkout: "/checkout",
        viewProduct: `/${links[0].catalog}-${links[0].link}`,
        removeFromCart: `/${links.slice(-1)[0].catalog}-${links.slice(-1)[0].link
          }`,
      }
      if (
        actionLinkMap[action.event_eventName] !== "/checkout" ||
        cartCount > 0
      ) {
        return (
          <div className="action-recommendation">
            <ConditionalLink
              redirectUrl={actionLinkMap[action.event_eventName]}
              className="flex flex-1 justify-end"
              onClick={() => setEventProperties(`initiateCheckout`)}
              isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
            >
              <div className="cursor-pointer flex">
                <p className="text-gray-600 text-sm mr-2">
                  {action.recommendation}
                </p>
                <FaLongArrowAltRight className="text-gray-600 mt-1" />
              </div>
            </ConditionalLink>
          </div>
        )
      }
      return null
    }
  }
  setAdobeParams() {
    if (
      window.adobe &&
      adobe.target &&
      typeof adobe.target.getOffers === "function"
    ) {
      const req = {
        request: {
          "prefetch": {
            "views": [
              {
                "profileParameters": {
                  "segmentMembership": "1234, 5678",
                  "gender": "Male"
                },
                "parameters": {
                  "segmentMembership": "1234, 5678",
                  "gender": "Male"
                },
              }
            ]
          }
        },
      }
      var offers = adobe?.target?.getOffers(req).then(response => { })
    }
    return
  }

  componentDidMount() {
    this.setAdobeParams()
    // this.recommendationPoller = isSymphony()
    //   ? setInterval(this.fetchRecommendation, 5000)
    //   : null
  }

  render() {
    const { children } = this.props
    return (
      <ContextProviderComponent>
        <SiteContext.Consumer>
          {context => {
            //console.log('baselayout rerendering...')
            let {
              navItems: {
                navInfo: { data: links },
              },
            } = context // links: {name: string, catalog: string}[]
            // Update the links according to the catalog selection on Config page

            links = getCatalogFilteredItems(links).map(link => {
              const newLink = {}
              newLink.catalog = link.catalog
              newLink.link = slugify(link.name)
              newLink.name = titleIfy(link.name)
              return newLink
            })
            return (
              <div className="min-h-screen">
                {isBrowser() && window.location.pathname !== "/" ? (
                  <nav id="nav" className="w-full bg-white fixed z-50 pb-1">
                    {getSiteConfigField("logoPosition") !== "center" ? (
                      <div
                        className="
                    h-12
                    w-fw
                    mx-4 mt-10
                    flex flex-col items-center
                    sm:flex-row"
                      >
                        <ConditionalLink
                          className="h-full"
                          redirectUrl="/home/"
                          onClick={() => setEventProperties("goToHome")}
                          isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                        >
                          <img
                            className="h-full object-contain mb-0 w-24 mw-24 sm:mr-5 sm:ml-5"
                            alt="Logo"
                            src={getSiteConfigField("logoUrl")}
                          />
                        </ConditionalLink>
                        <div className="flex flex-wrap">
                          {links.map((l, i) => {
                            if (isSymphony() && l?.catalog !== "automobiles") {
                              return (
                                <ConditionalLink
                                  redirectUrl={`/${slugify(l.catalog.toLowerCase())}-${l.link}`}
                                  key={i}
                                  onClick={() =>
                                    setEventProperties("selectCategory", {
                                      categoryName: l.name,
                                    })
                                  }
                                  isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                                >
                                  <p
                                    key={i}
                                    className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold"
                                  >
                                    {l.name}
                                  </p>
                                </ConditionalLink>
                              )
                            } else if (!isSymphony()) {
                              return (
                                <ConditionalLink
                                  redirectUrl={`/${slugify(l.catalog.toLowerCase())}-${l.link}`}
                                  key={i}
                                  onClick={() =>
                                    setEventProperties("selectCategory", {
                                      categoryName: l.name,
                                    })
                                  }
                                  isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                                >
                                  <p
                                    key={i}
                                    className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold"
                                  >
                                    {l.name}
                                  </p>
                                </ConditionalLink>
                              )
                            }
                            return null
                          })}
                        </div>
                        <ConditionalLink redirectUrl="/contact-us/" className="" isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}>
                          <p className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold">
                            Contact Us
                          </p>
                        </ConditionalLink>
                        <div className="ml-8 bg-white search-bar">
                          <GrFormSearch className="text-3xl" />
                          <input
                            placeholder="Search Items"
                            aria-label="search"
                            name="search"
                            className=""
                            id="search"
                            type="text"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="mobile:px-10 px-4 flex justify-center flex-col">
                        <div
                          className="
                      h-12
                      w-fw
                      mx-auto mt-4
                      flex flex-col items-center
                      sm:flex-row"
                        >
                          <div className="bg-white search-bar">
                            <GrFormSearch className="text-3xl" />
                            <input
                              placeholder="Search Items"
                              aria-label="search"
                              name="search"
                              className=""
                              id="search"
                              type="text"
                            />
                          </div>
                          <ConditionalLink
                            redirectUrl="/home/"
                            onClick={() => setEventProperties("goToHome")}
                            className="h-full logo-center"
                            isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                          >
                            <img
                              className="h-full object-contain mb-0 w-24 mw-24 sm:mr-5 sm:ml-5"
                              alt="Logo"
                              src={getSiteConfigField("logoUrl")}
                            />
                          </ConditionalLink>
                        </div>
                        <div className="flex flex-wrap flex-col sm:flex-row my-2 justify-center">
                          {links.map((l, i) => {
                            if (isSymphony() && l?.catalog !== "automobiles") {
                              return (
                                <ConditionalLink
                                  redirectUrl={`/${slugify(l.catalog.toLowerCase())}-${l.link}`}
                                  key={i}
                                  onClick={() =>
                                    setEventProperties("selectCategory", {
                                      categoryName: l.name,
                                    })
                                  }
                                  isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                                >
                                  <p
                                    key={i}
                                    className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold"
                                  >
                                    {l.name}
                                  </p>
                                </ConditionalLink>
                              )
                            } else if (!isSymphony()) {
                              return (
                                <ConditionalLink
                                  redirectUrl={`/${slugify(l.catalog.toLowerCase())}-${l.link}`}
                                  key={i}
                                  onClick={() =>
                                    setEventProperties("selectCategory", {
                                      categoryName: l.name,
                                    })
                                  }
                                  isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}
                                >
                                  <p
                                    key={i}
                                    className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold"
                                  >
                                    {l.name}
                                  </p>
                                </ConditionalLink>
                              )
                            }
                            return null
                          })}
                          <ConditionalLink redirectUrl="/contact-us/" className="" isMultiPageSite={getSiteConfigField('multiPageSite') === 'true'}>
                            <p className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold">
                              Contact Us
                            </p>
                          </ConditionalLink>
                        </div>
                      </div>
                    )}
                  </nav>
                ) : null}
                <div className="mobile:px-10 px-4 pb-10 flex justify-center">
                  <main
                    className={
                      ((getSiteConfigField("logoPosition") === "center"
                        ? "w-fw mt-32"
                        : "w-fw mt-24") + (links[0].catalog === 'H&M' ? ' lg:w-2/3' : ''))
                    }
                  >
                    {children}
                  </main>
                </div>
                <div>
                  {this.getProductRecommendations(this.state.recommendation)}
                </div>
                <footer className="flex justify-center">
                  <div className="flex w-fw px-8 desktop:px-0 border-solid border-t border-gray-300 items-center">
                    <div>
                      {this.getActionRecommendation(
                        this.state.recommendation,
                        links
                      )}
                    </div>
                    <div className="flex flex-1 justify-end m-6">
                      <Link to="https://shopping.zeotap.com" className="">
                        <p className="text-left m-0 text-smaller mr-4 sm:mr-8 font-semibold">
                          Go to shopping app
                        </p>
                      </Link>
                      {!isSymphony() && (
                        <Link
                          className="ml-4"
                          to="/home"
                          onClick={() => setEventProperties(`loginClicked`)}
                        >
                          <p className="text-xs mb-0">Admins</p>
                        </Link>
                      )}
                      {!isSymphony() && (
                        <Link className="ml-4" to="/mocktcf/">
                          <p className="text-xs mb-0">Mock TCF</p>
                        </Link>
                      )}
                      {!isSymphony() && (
                        <Link className="ml-4" to="/identities/">
                          <p className="text-xs mb-0">User Identities</p>
                        </Link>
                      )}
                      <Link className="ml-4" to="/">
                        <p className="text-xs mb-0">Config</p>
                      </Link>
                      <button
                        onClick={() => this.resetGDPR()}
                        className="ml-4 text-xs mb-0"
                        type="button"
                      >
                        Reset Consent
                      </button>
                      <button
                        onClick={() => this.clearStorage()}
                        className="ml-4 text-xs mb-0"
                        type="button"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </footer>
              </div>
            )
          }}
        </SiteContext.Consumer>
      </ContextProviderComponent>
    )
  }
}

export default Layout
